import { axiosLegoBricks } from "./axios.service";

export class ThemeService {
   
    public getThemeNameById = async(themeId: string) => {
       try {
        const response = await axiosLegoBricks().get(`/Theme/${themeId}`);
        return response.data.name;
       } catch (e) {
           return 'NOT FOUND UPDATE THEME LIST';
       }
    }
}