import React, { FunctionComponent, useState } from "react";
import { IconButton, InputBase, Paper } from "@material-ui/core";
import styles from './SearchBar.module.scss';

import { Clear, Search } from "@material-ui/icons";
import { mapStateToProps } from "../../store/propMapping";
import { connect } from "react-redux";

interface ISearchBarProps {
    objects: any[];
    filteredObjects: (objects: any[]) => void;
    filterAttr: string;
    searchString?: (inputText: string) => void;
}

type ReduxType = ReturnType<typeof mapStateToProps>;

const SearchBar: FunctionComponent<ISearchBarProps & ReduxType> = (props: ISearchBarProps & ReduxType): React.ReactElement => {
    const [searchText, setSearchText] = useState('');
    return (
        <Paper elevation={3} className={styles.searchBar}>
            <InputBase
                onChange={(event) => {
                    const inputText = event.target.value.toLowerCase();
                    setSearchText(inputText);
                    const filteredHaves = props.objects.filter((object: any) => object[props.filterAttr].includes(inputText))
                    props.filteredObjects(filteredHaves);
                }}
                value={searchText}
                className={styles.searchInput}
                placeholder="Zoeken"
            />
            {
                searchText.length > 0 &&
                    <IconButton onClick={() => {
                        props.filteredObjects(props.objects);
                        setSearchText('');
                    }}>
                        <Clear />
                    </IconButton>
            }
            {searchText.length > 0 && props.isAdmin && props.searchString &&
                <IconButton onClick={() => {
                    props.searchString && props.searchString(searchText);
                }} >
                    <Search />
                </IconButton>
            }

        </Paper>
    )
}

export default connect(mapStateToProps)(SearchBar);