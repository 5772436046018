import { Have } from "./have.model";
export class AlternateSet {
    constructor(
        public id: number = 0,
        public name: string = '',
        public partsQuantity: number = 0,
        public url: string = '',
        public imageUrl: string = '',
        public designerName: string = '',
        public designerUrl: string = '',
        public have: Have= new Have()
    ) { }
}

