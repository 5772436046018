import { DataService } from "../data/DataService";
import { Need } from "../models/need.model";
import { axiosLegoBricks, axiosRebrickable } from "./axios.service";


export class NeedService {

  private _dataService: DataService;

  constructor(dataService: DataService) {
    this._dataService = dataService;
  }
  
  public get = async (): Promise<Need[]> => {
    const response = await axiosLegoBricks().get("/Need");
    return response.data;
  };

  public byElementId = async (elementId: string) => {
    return axiosRebrickable
      .get(`/elements/${elementId}/`)
      .then(async (response) => await this._dataService.rebrickableToNeed(response.data))
      .catch(() => null);
  };

  public sparesExist = async (elementId: string) => {
    return axiosLegoBricks()
      .get(`/Need/elements/${elementId}`)
      .catch(() => null);
  };

  public save = async (need: Need): Promise<Need> => {
    const response = await axiosLegoBricks().post("/Need", need);
    return response.data;
  };

  public updateChecked = async (id: number, checked: boolean) => {
    const response = await axiosLegoBricks().patch(`/Need/checked/${id}`, [
      {
        value: checked,
        path: "/checked",
        op: "replace",
      },
    ]);
    return response.data;
  };

  public updateAmount = async (id: number, amount: number): Promise<Need> => {
    const response = await axiosLegoBricks().patch(`/Need/amount/${id}`, [
      {
        value: amount,
        path: "/amount",
        op: "replace",
      },
    ]);
    return response.data;
  };

  public delete = async (id: number) => {
    const response = await axiosLegoBricks().delete(`/Need/${id}`);
    return response.data;
  };
}
