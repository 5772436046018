import { Set } from "./set.model";
export class Sale extends Set {
    constructor(
        public id: number = 0,
        public no: string = '',
        public name: string = '',
        public release: number = 0,
        public partsQuantity: number = 0,
        public imageUrl: string = '',
        public themeName: string = '',
        public themeId: number = 0,
        public closed: boolean = true,
        public purchasePrice: number = 0,
        public sold: boolean = false,
        public sellingPrice: number = 0
    ) {
        super(id, no, name, release, partsQuantity, imageUrl, themeName, themeId);
    }
}