import { faMoneyBillAlt, faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React from 'react';
import AppContext, { AppContextProps } from '../../data/context';
import { SaleTypes } from '../../data/Enums';
import { Sale } from '../../models/sale.model';
import SearchBar from '../searchBar/SearchBar';
import { ISaleContainerProps, ISaleContainerState } from "./ISaleContainer";
import { SaleList } from './saleList/SaleList';
import { SoldList } from './soldList/SoldList';

class SaleContainer extends React.Component<ISaleContainerProps, ISaleContainerState> {


    public context!: AppContextProps;
    public static contextType: React.Context<AppContextProps> = AppContext;

    public state: ISaleContainerState = {
        possibleSales: [],
        sales: [],
        solds: [],
        loaded: false,
        saleType: SaleTypes.Sale
    };

    private getPossibleSales = async () => {
        const possibleSales = await this.context.haveService.sales();
        this.setState({ possibleSales });
    }

    public async componentDidMount() {
        this.context.setShowLoadingBackground(true);
        this.context.setNavTitle("Selling Sets");
        const sales = await this.context.saleService.getForSales();
        const solds = await this.context.saleService.getSolds();
        this.setState({ sales, solds, loaded: true });
        await this.getPossibleSales();
        this.context.setShowLoadingBackground(false);
    }

    public render(): React.ReactElement<ISaleContainerProps> {
        return (<>
            <SearchBar
                objects={this.state.saleType === SaleTypes.Sold ? this.state.solds : this.state.solds}
                filteredObjects={(filtered: Sale[]) => { console.log(filtered) }}
                filterAttr="no" />
            <div style={{ textAlign: "center" }}>
                <ToggleButtonGroup value={this.state.saleType} exclusive onChange={(_, value) => {
                    this.setState(prev => ({ saleType: value ? value : prev.saleType }))
                }}>
                    <ToggleButton value={SaleTypes.Sale}>
                        <FontAwesomeIcon size="lg" icon={faMoneyCheckAlt} />
                    </ToggleButton>
                    <ToggleButton value={SaleTypes.Sold}>
                        <FontAwesomeIcon size="lg" icon={faMoneyBillAlt} />
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>

            {this.state.saleType === SaleTypes.Sale &&
                <SaleList
                    sales={this.state.sales}
                    possibleSales={this.state.possibleSales}
                    addSold={(selledSale: Sale) => {
                        const moreProfit = this.state.solds.filter((sold: Sale) => (sold.sellingPrice - sold.purchasePrice) > (selledSale.sellingPrice - selledSale.purchasePrice));
                        const lessProfit = this.state.solds.filter((sold: Sale) => (sold.sellingPrice - sold.purchasePrice) <= (selledSale.sellingPrice - selledSale.purchasePrice));
                        this.setState({ solds: [...moreProfit, selledSale, ...lessProfit] });
                    }}
                    getPossibleSales={async () => await this.getPossibleSales()} />}
            {this.state.saleType === SaleTypes.Sold && <SoldList solds={this.state.solds} />}</>);
    }
}

export default SaleContainer;