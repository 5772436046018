import { ActionType } from "typesafe-actions";
import { SetAdmin, SetAuthenticated, SetUnAuthenticated, UnsetAdmin } from "./actions";

export interface IAdminState {
    isAdmin: boolean;
}

export const SET_ADMIN = '[Admin] Set Admin';
export const UNSET_ADMIN = '[Admin] Unset Admin';

export interface IAuthState {
    isAuthenticated: boolean;
}

export const SET_AUTHENTICATED = '[Auth] Set Authenticated';
export const SET_UNAUTHENTICATED = '[Auth] Set Unauthenticated';

export type Actions = ActionType<typeof SetAdmin | typeof UnsetAdmin | typeof SetAuthenticated | typeof SetUnAuthenticated>;
