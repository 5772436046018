import React from "react";
import { connect } from "react-redux";
import AppContext, { AppContextProps } from "../../../data/context";
import { mapStateToProps } from "../../../store/propMapping";
import { IBLSetListProps, IBLSetListState } from "./IBLSetList";
import styles from "../../../assets/css/SetList.module.scss";
import SearchBar from "../../searchBar/SearchBar";
import GridView from "../../Generic/GridView";
import { BLInventory } from "../../../models/blInventory.model";
import { SkeletonComponent } from "../../skeleton/Skeleton";

type ReduxType = ReturnType<typeof mapStateToProps>;

class BLSetList extends React.Component<IBLSetListProps & ReduxType, IBLSetListState> {

    public context!: AppContextProps;
    public static contextType: React.Context<AppContextProps> = AppContext;

    public state: IBLSetListState = {
        blSets: [],
        filteredBLSets: [],
        loaded: false
    };

    public async componentDidMount() {
        this.context.setNavTitle('Sets');
        //this.context.setShowLoadingBackground(true);
        const blSets = await this.context.brickLinkService.getSets();
        this.setState({ blSets, filteredBLSets: blSets, loaded: true });
        //this.context.setShowLoadingBackground(false);
    }

    public render(): React.ReactElement<IBLSetListProps> {
        return (
            <div className={styles.setList}>

                <SearchBar
                    objects={this.state.blSets}
                    filteredObjects={(filteredBLSets: BLInventory[]) => { this.setState({ filteredBLSets }) }}
                    filterAttr="no" />

                <div className={styles.setContainer}>
                    {
                        // this.state.loaded ? this.state.filteredHaves.length > 0 ?
                       this.state.loaded ? this.state.filteredBLSets.map((blSet: BLInventory, index: number) =>
                            <GridView
                                blInventory={blSet}
                                key={index}
                                replaceInventory={(inventory: BLInventory) => {
                                    const blSets = this.context.dataService.replaceObjectByProperty(this.state.blSets, "inventoryId", inventory);
                                    this.setState({ blSets, filteredBLSets: blSets });
                                }}
                                removeInventory={(inventoryId: number) => {
                                    const remainingBLSets = this.state.blSets.filter(x => x.inventoryId !== inventoryId);
                                    this.setState(({ blSets: remainingBLSets, filteredBLSets: remainingBLSets  }));
                                }}
                            />) : <SkeletonComponent />
                        // : <div style={{ textAlign: 'center' }}><h3>No sets found!</h3></div> : <div style={{ textAlign: 'center' }}><h3>Loading...</h3></div>
                    }
                </div>
            </div>);
    }
}

export default connect(mapStateToProps,)(BLSetList);