
import React, { FunctionComponent, useEffect, useState } from "react";
import styles from './SaleList.module.scss';
import { Autocomplete } from "@material-ui/lab";
import { Have } from "../../../models/have.model";
import { Button, TextField } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStoreAlt } from "@fortawesome/free-solid-svg-icons";
import { Sale } from "../../../models/sale.model";
import SaleItem from "./saleItem/SaleItem";
import { PriceDialog } from "../../dialogs/PriceDialog";
import { ConfirmationDialog } from "../../dialogs/ConfirmationDialog";
import { useContext } from "react";
import AppContext from "../../../data/context";

interface ISaleListProps {
    possibleSales: Have[];
    sales: Sale[];
    addSold: (sale: Sale) => void;
    getPossibleSales: () => void;
}

export const SaleList: FunctionComponent<ISaleListProps> = (props: ISaleListProps): React.ReactElement => {
    const [sale, setSale] = useState(new Sale());
    const [have, setHave] = useState(new Have());
    const [sales, setSales] = useState(new Array<Sale>());
    const [showPriceDialog, setShowPriceDialog] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [selected, setSelected] = useState(false);
    const context = useContext(AppContext);

    

    useEffect(() => {
        setSales(props.sales);
    }, [props.sales]);

    return (
        <div className={styles.saleList}>
            <Autocomplete
                value={have}
                options={props.possibleSales}
                getOptionLabel={(have: Have) => `${have.no} ${have.name}`}
                style={{ maxWidth: 500, width: "100%", marginTop: 10 }}
                //onChange={(_, value) => setSale(value ?  : new Sale())}
                onChange={(_, value) => {
                    if (value) {
                        setHave(value);
                        setSale(new Sale(0, value.no, value.name, value.release, value.partsQuantity, value.imageUrl, value.themeName, value.themeId, value.closed, value.purchasePrice, false, 0));
                        setSelected(true);
                    } else {
                        setSelected(false);
                    }
                    
                    // setSale(prev => {
                    //     const have = value as Have;
                    //     have.alternateSets = undefined;
                    //     have.needs = undefined;
                    //     delete value.alternateSets;
                    //     delete value.needs;
                    //     const newSale: Sale = Object.assign(prev, value, { id: 0, alternates: undefined, needs: undefined });
                    //     return newSale as Sale
                    // });
                }
                }
                renderInput={(params) => <TextField {...params} label="Possible Sales" variant="outlined" />}
            />
            <Button
                variant="contained"
                disabled={!selected}
                color="secondary"
                onClick={async () => {
                    context.setShowLoadingBackground(true);
                    const savedSale = await context.saleService.save(sale);
                    const moreExpensive = props.sales.filter((sale: Sale) => sale.purchasePrice > savedSale.purchasePrice);
                    const lessExpensive = props.sales.filter((sale: Sale) => sale.purchasePrice <= savedSale.purchasePrice);
                    setSales([...moreExpensive, savedSale, ...lessExpensive]);
                    props.getPossibleSales();
                    setHave(new Have());
                    setSale(new Sale());
                    context.setShowLoadingBackground(false);

                    // this.setState(prev => ({ sales: [savedSale, ...prev.sales], possibleSales: prev.possibleSales.filter((have: Have) => have.no !== savedSale.no) }));
                }}
                style={{ margin: "8px 0" }}
                startIcon={<FontAwesomeIcon size="lg" icon={faStoreAlt} />}>Add to Sell list</Button>

            <div className={styles.setContainer}>
                {sales.map((sale: Sale) =>
                    <SaleItem
                        key={sale.id}
                        sale={sale}
                        deleteSale={async (sale: Sale) => {
                            setSale(sale);
                            setShowConfirmationDialog(true);
                        }}
                        sell={(sale: Sale) => {
                            setSale(sale);
                            setShowPriceDialog(true);
                        }}
                    />)}
            </div>
            <PriceDialog
                show={showPriceDialog}
                title={`Sell ${sale.no} - ${sale.name}`}
                content={<p>What was the selling price?</p>}
                handlePrice={async (price: number) => {
                    context.setShowLoadingBackground(true);
                    const selledSale = await context.saleService.sell(sale.id, price);
                    const reducedSales = sales.filter((sale: Sale) => sale.id !== selledSale.id);
                    setSales(reducedSales);
                    props.addSold(selledSale);
                    context.getTotalSpent();
                    context.setShowLoadingBackground(false);
                }}
                handleClose={() => setShowPriceDialog(false)} />
            <ConfirmationDialog
                show={showConfirmationDialog}
                title={`Deleting ${sale.name}`}
                handleConfirmation={async (confirm: boolean) => {
                    setShowConfirmationDialog(false);
                    if (confirm) {
                        context.setShowLoadingBackground(true);
                        const removedSale = await context.saleService.delete(sale.id);
                        const reducedSales = sales.filter((sale: Sale) => sale.id !== removedSale.id);
                        setSales(reducedSales);
                        props.getPossibleSales();
                        setHave(new Have());
                        setSale(new Sale());
                        context.setShowLoadingBackground(false);
                    }
                }} ><p>Are you sure you want to delete this set with no {sale.no} ?</p></ConfirmationDialog>
        </div>
    );

}