import { faFolder, faHome, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf, faFileWord, faFilePowerpoint, faFileExcel, faFileArchive, faFile, faFileImage } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItem, ListItemText, ListItemIcon, Breadcrumbs, Typography, Link, ListItemSecondaryAction, IconButton, Menu, MenuItem, TextField, Button } from '@material-ui/core';
import React from 'react';
import { FtpItemInfo } from '../../models/ftpItemInfo.model';
import { FtpLocation } from '../../models/ftpLocation.model';
import { IInstructionsProps, IInstructionsState } from './IInstructions';
import styles from './Instructions.module.scss';
import MoreVert from '@material-ui/icons/MoreVert';
import NoteAdd from '@material-ui/icons/NoteAdd';
import { CreateNewFolder } from '@material-ui/icons';
import { ConfirmationDialog } from '../dialogs/ConfirmationDialog';
import { Alert } from '@material-ui/lab';
import AppContext, { AppContextProps } from '../../data/context';

// https://graph.microsoft.com/v1.0/drives/d6a74a89e2a08b2/root:/LegoBricks:/children?$select=name,webUrl,folder

const fileIcons: { [key: string]: IconDefinition } = {
    pdf: faFilePdf,
    docx: faFileWord,
    pptx: faFilePowerpoint,
    xlsx: faFileExcel,
    zip: faFileArchive,
    jpg: faFileImage,
    png: faFileImage,
    jpeg: faFileImage
};

class Instructions extends React.Component<IInstructionsProps, IInstructionsState> {

    public context!: AppContextProps;
    public static contextType: React.Context<AppContextProps> = AppContext;

    public state: IInstructionsState = {
        ftpItemInfos: [],
        currentPaths: [],
        currentTarget: undefined,
        currentFtpItemInfo: new FtpItemInfo(),
        showMakeDirectoryDialog: false,
        directoryName: '',
        showRenameDialog: false,
        newName: '',
        srcMovePath: '',
        srcPath: '',
        showDeleteDialog: false,
        alertMessage: undefined
    }

    public async componentDidMount() {
        this.context.setNavTitle('Instructions');
        const ftpItemInfos: FtpItemInfo[] = await this.context.instructionService.getLibraryRootInfo(new FtpLocation(1, '/'), this.setAlert);
        this.setState({ ftpItemInfos });
    }

    private goToLocation = async (path: string) => {
        const ftpItemInfos = await this.context.instructionService.getLibraryRootInfo(new FtpLocation(1, path), this.setAlert);
        const currentPaths = path.split('/').filter(x => x.length > 0).map((pathItem: string, index: number, pathItems: string[]) => ({ name: pathItem, path: `/${pathItems.slice(0, index + 1).join('/')}/` }));
        this.setState({ ftpItemInfos, currentPaths, currentTarget: null });
    }

    private getExtension = (fileName: string) => fileName.split('.').pop();

    private getIcon = (fileName: string): IconDefinition => {
        const extension = this.getExtension(fileName)!;
        const iconDef = fileIcons[extension];
        return iconDef ? iconDef : faFile;
    }

    private getCurrentPath = () => {
        const location = [...this.state.currentPaths].pop();
        return location ? (location as FtpItemInfo).path : '/';
    }

    private setAlert = (alertMessage?: string) => {
        this.setState({ alertMessage });
    }

    public render(): React.ReactElement<IInstructionsProps> {
        return (
            <div className={styles.instructions}>
                {this.state.alertMessage && <Alert onClose={() => this.setAlert(undefined)} severity="error">{this.state.alertMessage}</Alert>}
                <Breadcrumbs aria-label="breadcrumb">
                    {this.state.currentPaths.length === 0 ? <Typography color="textPrimary" onClick={async () => await this.goToLocation('/')}><FontAwesomeIcon size="sm" icon={faHome} /></Typography> : <Link className={styles.pointer} color="inherit" onClick={async () => await this.goToLocation('/')}><FontAwesomeIcon size="sm" icon={faHome} /></Link>}
                    {
                        this.state.currentPaths.map((currentPath: { name: string, path: string }, index: number) => {
                            return index + 1 === this.state.currentPaths.length ?
                                <Typography key={index} color="textPrimary" onClick={async () => {
                                    await this.goToLocation(currentPath.path);
                                }}>{currentPath.name}</Typography> :

                                <Link className={styles.pointer} key={index} color="inherit" onClick={async () => {
                                    await this.goToLocation(currentPath.path);
                                }}>{currentPath.name}</Link>
                        })}
                </Breadcrumbs>

                <label htmlFor="uploadButton">
                    <input style={{ display: "none" }} accept="*" id="uploadButton" type="file" onChange={(event) => {
                        this.context.setShowLoadingBackground(true);
                        const currentPath = this.getCurrentPath();
                        Array.from(event.target.files!).forEach(async (file: File) => {
                            await this.context.instructionService.uploadFile(file, 1, `${currentPath}${file.name}`, this.setAlert);
                            await this.goToLocation(currentPath);
                            this.context.setShowLoadingBackground(false);
                        });
                    }} />
                    <IconButton color="secondary" component="span">
                        <NoteAdd />
                    </IconButton>
                </label>
                <IconButton color="secondary" onClick={() => this.setState({ showMakeDirectoryDialog: true })}>
                    <CreateNewFolder />
                </IconButton>
                {this.state.srcMovePath.length > 0 && <>
                    {this.getCurrentPath() !== this.state.srcPath && <Button color="primary" onClick={async () => {
                        const currentLocation = this.getCurrentPath();
                        const newPath = `${currentLocation}${this.state.currentFtpItemInfo.name}`;
                        await this.context.instructionService.rename(new FtpLocation(1, this.state.currentFtpItemInfo.isDocument
                            ? this.state.srcMovePath
                            : this.state.srcMovePath.slice(0, -1), newPath), this.setAlert);
                        await this.goToLocation(currentLocation)
                        this.setState({ srcMovePath: '', srcPath: '' });
                    }}>Move to here</Button>}
                    <Button color="secondary" onClick={() => this.setState({ srcMovePath: '' })}>Cancel move</Button>
                </>}
                <List dense>
                    {this.state.ftpItemInfos.map((ftpItemInfo: FtpItemInfo, index: number) => {
                        return (
                            <ListItem key={index} button onClick={async () => {
                                ftpItemInfo.isDocument
                                    ? await this.context.instructionService.downLoadFile(new FtpLocation(1, ftpItemInfo.path), this.setAlert)
                                    : await this.goToLocation(ftpItemInfo.path);
                            }}>
                                <ListItemIcon>
                                    <FontAwesomeIcon size="lg" icon={ftpItemInfo.isDocument ? this.getIcon(ftpItemInfo.name) : faFolder} />
                                </ListItemIcon>
                                <ListItemText primary={ftpItemInfo.name} />
                                <ListItemSecondaryAction className={styles.endButton}>
                                    <IconButton edge="end" onClick={(event) => {
                                        this.setState({ currentTarget: event.currentTarget, currentFtpItemInfo: ftpItemInfo });
                                    }}>
                                        <MoreVert />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
                <Menu
                    keepMounted
                    anchorEl={this.state.currentTarget}
                    open={Boolean(this.state.currentTarget)}>
                    <MenuItem onClick={async () => {
                        this.setState({ showRenameDialog: true, newName: this.state.currentFtpItemInfo.name.split('.')[0] });
                    }}>Rename</MenuItem>
                    <MenuItem onClick={async () => {
                        this.setState({ srcMovePath: this.state.currentFtpItemInfo.path, currentTarget: null, srcPath: this.getCurrentPath() });
                    }}>Move</MenuItem>
                    <MenuItem onClick={async () => {
                        this.setState({ showDeleteDialog: true });
                    }}>Delete</MenuItem>

                    <MenuItem onClick={() => this.setState({ currentTarget: null })}>Close</MenuItem>
                </Menu>
                <ConfirmationDialog
                    show={this.state.showMakeDirectoryDialog}
                    title="Create New Directory"
                    handleConfirmation={async (confirm: boolean) => {
                        if (confirm) {
                            const currentPath = this.getCurrentPath();
                            await this.context.instructionService.createDirectory(new FtpLocation(1, `${currentPath}${this.state.directoryName}`), this.setAlert);
                            await this.goToLocation(currentPath);
                            this.setState({ showMakeDirectoryDialog: false });
                        } else {
                            this.setState({ showMakeDirectoryDialog: false });
                        }
                    }}>
                    <div>
                        <TextField style={{ width: 500, maxWidth: "100%" }} label="Directory Name" onChange={(event) => {
                            this.setState({ directoryName: event.target.value });
                        }} />
                    </div>
                </ConfirmationDialog>
                <ConfirmationDialog
                    show={this.state.showRenameDialog}
                    title="Rename"
                    handleConfirmation={async (confirm: boolean) => {
                        if (confirm) {
                            const currentPath = this.getCurrentPath();
                            const newPath = `${currentPath}${this.state.newName}${this.state.currentFtpItemInfo.isDocument ? `.${this.state.currentFtpItemInfo.name.split('.').pop()}` : ''}`;
                            await this.context.instructionService.rename(new FtpLocation(1, this.state.currentFtpItemInfo.isDocument
                                ? this.state.currentFtpItemInfo.path
                                : this.state.currentFtpItemInfo.path.slice(0, -1), newPath), this.setAlert);
                            await this.goToLocation(currentPath);
                            this.setState({ showRenameDialog: false });
                        } else {
                            this.setState({ showRenameDialog: false, currentTarget: null });
                        }
                    }}
                > <div>
                        <TextField style={{ width: 500, maxWidth: "100%" }} label="New Name" value={this.state.newName} onChange={(event) => {
                            this.setState({ newName: event.target.value });
                        }} />
                    </div>
                </ConfirmationDialog>
                <ConfirmationDialog
                    show={this.state.showDeleteDialog}
                    title="Delete"
                    handleConfirmation={async (confirm: boolean) => {
                        if (confirm) {
                            this.state.currentFtpItemInfo.isDocument
                                ? await this.context.instructionService.deleteFile(new FtpLocation(1, this.state.currentFtpItemInfo.path), this.setAlert)
                                : await this.context.instructionService.removeDirectory(new FtpLocation(1, this.state.currentFtpItemInfo.path), this.setAlert);
                            const currentPath = this.getCurrentPath();
                            await this.goToLocation(currentPath);
                            this.setState({ showDeleteDialog: false });
                        } else {
                            this.setState({ showDeleteDialog: false });
                        }
                    }}
                > <p style={{ wordBreak: "break-word" }}>Are you sure you want to delete this file or folder: <span style={{ fontWeight: 'bold' }}>{this.state.currentFtpItemInfo.name}</span></p>
                </ConfirmationDialog>
            </div >
        )
    }
}

export default Instructions;