import React, { useContext, useState } from 'react';
import { Avatar, Card, CardContent, CardHeader, CardMedia, Collapse, FormControlLabel, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, Switch } from "@material-ui/core";
import { FunctionComponent } from "react";
import { Have } from '../../../models/have.model';
import { ExpandLess, ExpandMore, Launch, Edit, MoreVert, Delete } from '@material-ui/icons';
import { AlternateSet } from '../../../models/alternateSet.model';
import { mapStateToProps } from '../../../store/propMapping';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faHashtag, faLightbulb, faMoneyBillAlt, faStore } from '@fortawesome/free-solid-svg-icons';
import { Need } from '../../../models/need.model';
import styles from './HaveItem.module.scss';
import AppContext from '../../../data/context';

interface IHaveItemProps {
    have: Have;
    updateHave: (built: boolean) => void;
    updatePrice: () => void;
    openOptionDialog: (have: Have) => void;
    deleteNeed: (need: Need, have: Have) => void;
}

type ReduxType = ReturnType<typeof mapStateToProps>;

const HaveItem: FunctionComponent<IHaveItemProps & ReduxType> = (props: IHaveItemProps & ReduxType): React.ReactElement => {
    const [openAlternates, setOpenAlternates] = useState(false);
    const [openNeeds, setOpenNeeds] = useState(false);
    const context = useContext(AppContext);

    return (
        <Card key={props.have.no} className={styles.haveItem}>
            <CardHeader
                titleTypographyProps={{ variant: 'h6' }}
                title={props.have.name}
                subheader={`${props.have.themeName}, ${props.have.release}`}
                action={
                    props.isAdmin && <IconButton onClick={() => {
                        props.openOptionDialog(props.have);
                    }}>
                        <MoreVert />
                    </IconButton>
                }
            />
            <CardMedia
                style={{ objectFit: "contain" }}
                component="img"
                alt={props.have.name}
                height="140"
                image={props.have.imageUrl}
            />
            <CardContent>
                <List dense={true}>
                    <ListItem>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={!props.isAdmin}
                                    checked={props.have.closed}
                                    onChange={(_, value) => {
                                        props.have.closed = value;
                                        props.updateHave(value);
                                    }}
                                    color="primary"
                                />
                            }
                            label={props.have.closed ? "Closed" : "Opened"}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faBuilding} />
                        </ListItemIcon>
                        <ListItemText primary={props.have.no} />
                    </ListItem>
                    {props.isAdmin && <ListItem button onClick={() => props.updatePrice()}>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faMoneyBillAlt} />
                        </ListItemIcon>
                        <ListItemText primary={context.dataService.moneyFormatter(props.have.purchasePrice)} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" onClick={() => props.updatePrice()} >
                                <Edit />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>}
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faHashtag} />
                        </ListItemIcon>
                        <ListItemText primary={`${props.have.partsQuantity} piece(s)`} />
                    </ListItem>
                    {props.have.alternateSets.length > 0 &&
                        <><ListItem button onClick={() => setOpenAlternates(!openAlternates)}>
                            <ListItemIcon>
                                <FontAwesomeIcon size="lg" icon={faLightbulb} />
                            </ListItemIcon>
                            <ListItemText primary="Alternates" />
                            {openAlternates ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                            <Collapse in={openAlternates} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {props.have.alternateSets.sort((b: AlternateSet, a: AlternateSet) => a.partsQuantity - b.partsQuantity).map((alternateSet: AlternateSet) => <ListItem key={alternateSet.id} button onClick={() => window.open(alternateSet.url, "_blank")}>
                                        <ListItemAvatar>
                                            <Avatar src={alternateSet.imageUrl} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={alternateSet.name}
                                            secondary={`${alternateSet.partsQuantity} piece(s)`}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" onClick={() => window.open(alternateSet.url, "_blank")} >
                                                <Launch />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>)}
                                </List>
                            </Collapse></>}
                    {props.have.needs.length > 0 &&
                        <><ListItem button onClick={() => setOpenNeeds(!openNeeds)}>
                            <ListItemIcon>
                                <FontAwesomeIcon size="lg" icon={faStore} />
                            </ListItemIcon>
                            <ListItemText primary="Missing piece(s)" />
                            {openNeeds ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                            <Collapse in={openNeeds} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {props.have.needs.map((need: Need) => <ListItem key={need.id} button>
                                        <ListItemAvatar>
                                            <Avatar src={need.imageUrl} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={need.name}
                                            secondary={`${need.amount}x ${need.elementId}`}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" onClick={() => props.deleteNeed(need, props.have)} >
                                                <Delete />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>)}
                                </List>
                            </Collapse></>}
                </List>
            </CardContent>
        </Card>
    )
}

export default connect(mapStateToProps)(HaveItem);