import { Snackbar } from '@material-ui/core';
import React from 'react';
import { FunctionComponent } from 'react';
import Alert, { Color } from '@material-ui/lab/Alert';

export const NotificationStyle = {
    Error: 'error' as Color,
    Warning: 'warning' as Color,
    Info: 'info' as Color,
    Success: 'success' as Color
}

interface INotificationProps {
    show: boolean;
    message: string;
    notificationStyle: Color;
    handleClose: () => void;
}

export const Notification: FunctionComponent<INotificationProps> = (props: INotificationProps): React.ReactElement => {
	return (
		<Snackbar open={props.show} autoHideDuration={2000} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} onClose={props.handleClose}>
			<Alert variant="filled" severity={props.notificationStyle} onClose={props.handleClose} style={{width: '400px'}}>
				{props.message}
			</Alert>
		</Snackbar>
	); 
};
