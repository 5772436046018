import React from 'react';
import styles from './Skeleton.module.scss';
import { FunctionComponent } from "react";
import { Skeleton } from '@mui/material';



export const SkeletonComponent: FunctionComponent<{}> = (): React.ReactElement => {

    return (
        <>
            {Array(12).fill(undefined).map((x, index) =>
                <div key={index} className={styles.skeleton}>
                    <Skeleton variant="text" />
                    <Skeleton variant="circular" width={50} height={50} />
                    <Skeleton variant="rectangular" height={250} />
                </div>)}
        </>

    )
}
