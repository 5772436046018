import axios from 'axios';
import { LocalStorageService } from './localstorage.service';

export const axiosRebrickable = axios.create({
	baseURL: 'https://rebrickable.com/api/v3/lego',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: 'Key ee5486617cc1b36d68a84375580fe4c4'
	}
});

export const axiosLegoBricks = () => axios.create({
	//baseURL: 'https://localhost:5001/api',
	baseURL: 'https://legobricks50.azurewebsites.net/api',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + new LocalStorageService().getToken()
	}
});

export const axiosLegoDriveBlob = () => axios.create({
	//baseURL: 'https://localhost:44322/Storage',
	baseURL: 'https://legodrive.azurewebsites.net/Storage',
	headers: {
		Authorization: 'Bearer bGVnb2JyaWNrczoxMjM0NTY='
	},
	responseType: 'blob',
});

export const axiosLegoDriveJson = () => axios.create({
	//baseURL: 'https://localhost:44322/Storage',
	baseURL: 'https://legodrive.azurewebsites.net/Storage',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: 'Bearer bGVnb2JyaWNrczoxMjM0NTY='
	}
});


export const axiosLegoDriveForm = () => axios.create({
	//baseURL: 'https://localhost:44322/Storage',
	baseURL: 'https://legodrive.azurewebsites.net/Storage',
	headers: {
		// 'Content-Type': 'multipart/form-data; boundary=something',
		Authorization: 'Bearer bGVnb2JyaWNrczoxMjM0NTY='
	}
});


export const axiosAWS = () => axios.create({
	baseURL: 'https://3ea09dcdlg.execute-api.eu-west-3.amazonaws.com/dev',
	headers: {
		// 'Content-Type': 'multipart/form-data; boundary=something',
		'X-API-KEY': 'pzL7EC68mB9dwU3N0f5hLawLNphDQnHU4rkKICET',
		'Content-Type': 'application-json',
		'Accept': '*'
	}
});

export const axiosAWSNews = () => axios.create({
	baseURL: 'https://tylb3w8pw3.execute-api.eu-west-3.amazonaws.com/dev',
	headers: {
		// 'Content-Type': 'multipart/form-data; boundary=something',
		'X-API-KEY': 'ezykGsuyTO2WDfCSbWMtn1pefth9xksZPAy9aIG4',
		'Content-Type': 'application-json',
		'Accept': '*'
	}
});



// export const axiosInstructions = () => {
// 	return axios.create({
// 		//baseURL: 'https://localhost:44322/Storage',
// 		baseURL: 'https://cors-anywhere.herokuapp.com/brickset.com/api/v3.asmx/getInstructions2?apiKey=3-zbe8-XlX5-SrtyR',
// 		headers: {
// 			'Content-Type': 'application/json',
// 		}
// 	});
// }


// export const axiosNews = () => {
// 	return axios.create({
// 		//baseURL: 'https://localhost:44322/Storage',
// 		baseURL: 'https://newsapi.org/v2',
// 		headers: {
// 			'Content-Type': 'application/json',
// 		},
// 		params: {
// 			q: "LEGO",
// 			apiKey: "2b4fc9879c3042ff8c20414d56cf67da",
// 			language: "nl",
// 			pageSize: "100"
// 		}
// 	});
// }

