import React from "react";
import { connect } from "react-redux";
import AppContext, { AppContextProps } from "../../data/context";
import { Have } from "../../models/have.model";
import { Want } from "../../models/want.model";
import { mapStateToProps } from "../../store/propMapping";
import { ConfirmationDialog } from "../dialogs/ConfirmationDialog";
import { PriceDialog } from "../dialogs/PriceDialog";
import { SearchDialog } from "../dialogs/searchDialog/SearchDialog";
import { DialogType, SetDialog } from "../dialogs/setDialog/SetDialog";
import SearchBar from "../searchBar/SearchBar";
import { SkeletonComponent } from "../skeleton/Skeleton";
import { IWantListProps, IWantListState } from "./IWantList";
import WantItem from "./wantItem/WantItem";
import styles from './WantList.module.scss';

type ReduxType = ReturnType<typeof mapStateToProps>;

class WantList extends React.Component<IWantListProps & ReduxType, IWantListState> {

    public context!: AppContextProps;
    public static contextType: React.Context<AppContextProps> = AppContext;

    public state: IWantListState = {
        ownWants: [],
        searchedWants: [],
        want: new Want(),
        showSetDialog: false,
        showSearchDialog: false,
        filteredWants: [],
        showConfirmationDialog: false,
        showPriceDialog: false,
        loaded: false
    };

    public async componentDidMount() {
        this.context.setNavTitle('Wanted Sets');
        //this.context.setShowLoadingBackground(true);
        const ownWants = await this.context.wantService.get();
        this.setState({ ownWants, filteredWants: ownWants, loaded: true });
        //this.context.setShowLoadingBackground(false);
    }


    private getWant = async (WantId: string) => {
        this.context.setShowLoadingBackground(true);
        const searchedWants = await Promise.all(await this.context.wantService.search(WantId));
        searchedWants.length === 1 ? this.setState({ want: searchedWants[0], showSetDialog: true }) : this.setState({ searchedWants, showSearchDialog: true });
        this.context.setShowLoadingBackground(false);
    }

    private makeHave = async (purchasePrice: number) => {
        this.context.setShowLoadingBackground(true);
        const have = Object.assign(new Have(), { purchasePrice }, this.state.want);
        have.id = 0;
        have.alternateSets = await this.context.haveService.getAlternateSets(this.state.want.no);
        await this.context.haveService.save(have);
        await this.removeWant();
        this.context.setShowLoadingBackground(false);
    }

    private removeWant = async () => {
        this.context.setShowLoadingBackground(true);
        const removedWant = await this.context.wantService.delete(this.state.want.id);
        const reducedWants = this.state.ownWants.filter((want: Want) => want.id !== removedWant.id);
        this.setState({ ownWants: reducedWants, filteredWants: reducedWants });
        this.context.setShowLoadingBackground(false);
    }

    public render(): React.ReactElement<IWantListProps> {
        return (
            <div className={styles.wantList}>
                <SearchBar
                    objects={this.state.ownWants}
                    filteredObjects={(filteredWants: Want[]) => { this.setState({ filteredWants }) }}
                    filterAttr="no"
                    searchString={(inputText: string) => this.getWant(inputText)} />

                <div className={styles.setContainer}>
                    {this.state.loaded ? this.state.filteredWants.length > 0 ?
                        this.state.filteredWants.map((want: Want) =>
                            <WantItem
                                key={want.id}
                                want={want}
                                makeHave={(want: Want) => {
                                    this.setState({ want, showPriceDialog: true })
                                }}
                                deleteWant={async (want: Want) => {
                                    this.setState({ want, showConfirmationDialog: true });
                                }}
                            />) : <div style={{ textAlign: 'center' }}><h3>Geen sets gevonden!</h3></div> : <SkeletonComponent />}
                </div>
                <SetDialog
                    set={this.state.want}
                    show={this.state.showSetDialog}
                    submitText={DialogType.Register}
                    handleShow={(showSetDialog) => this.setState({ showSetDialog })}
                    handleSubmit={async () => {
                        this.context.setShowLoadingBackground(true);
                        const savedWant = await this.context.wantService.save(this.state.want);

                        this.setState(prev => {
                            const morePieces = prev.ownWants.filter((want: Want) => want.partsQuantity > savedWant.partsQuantity);
                            const lessPieces = prev.ownWants.filter((want: Want) => want.partsQuantity <= savedWant.partsQuantity);
                            return { ownWants: [...morePieces, savedWant, ...lessPieces], filteredWants: [...morePieces, savedWant, ...lessPieces] }
                        });
                        this.context.setShowLoadingBackground(false);
                    }}
                    registerPrice={false} />
                <SearchDialog
                    show={this.state.showSearchDialog}
                    results={this.state.searchedWants.map((want: Want) => ({ no: want.no, title: want.name, subtitle: want.themeName, imageUrl: want.imageUrl }))}
                    handleShow={(showSearchDialog) => this.setState({ showSearchDialog })}
                    handleSubmit={(resultNo: string) => {
                        const want = this.state.searchedWants.find((want: Want) => want.no === resultNo);
                        if (want) {
                            this.setState({ want, showSetDialog: true });
                        }
                        this.setState({ showSearchDialog: false });
                    }} />

                <ConfirmationDialog
                    show={this.state.showConfirmationDialog}
                    title={`Deleting ${this.state.want.name}`}
                    handleConfirmation={async (confirm: boolean) => {
                        if (confirm) {
                            this.context.setShowLoadingBackground(true);
                            this.removeWant();
                            this.context.setShowLoadingBackground(false);
                        }
                        this.setState({ showConfirmationDialog: false })
                    }}><p>Are you sure you want to delete this set with no {this.state.want.no} ?</p></ConfirmationDialog>
                <PriceDialog
                    show={this.state.showPriceDialog}
                    title={`Own ${this.state.want.no} - ${this.state.want.name}`}
                    content={<p>What Price did you pay for this set?</p>}
                    handlePrice={(price: number) => this.makeHave(price)}
                    handleClose={() => this.setState({ showPriceDialog: false })} />
            </div>);
    }
}

export default connect(mapStateToProps,)(WantList);