import { Navigation } from "./Interfaces";
import { Store, Favorite, LocationCity } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faCube, faFolder, faNewspaper, faPiggyBank, faShoppingBasket, faUser } from '@fortawesome/free-solid-svg-icons';

export const navigationLegoBricks: Navigation[] = [
  { link: '/HaveList', value: 'Have', icon: <LocationCity />, admin: false },
  { link: '/WantList', value: 'Want', icon: <Favorite />, admin: false },
  { link: '/NeedList', value: 'Need', icon: <Store />, admin: false },
  // { link: '/SpareList', value: 'Spare', icon: <Extension />, admin: false },
  // { link: '/MinifigList', value: 'Minifig', icon: <FontAwesomeIcon size="lg" icon={faUserAstronaut} />, admin: false },
  { link: '/SaleList', value: 'Sale', icon: <FontAwesomeIcon size="lg" icon={faPiggyBank} />, admin: true },
  { link: '/Instructions', value: 'Instructions', icon: <FontAwesomeIcon size="lg" icon={faFolder} />, admin: false },
  { link: '/News', value: 'News', icon: <FontAwesomeIcon icon={faNewspaper} />, admin: false },
];

export const navigationBrickLink: Navigation[] = [
  { link: '/BLSetList', value: 'Sets', icon: <FontAwesomeIcon icon={faBuilding} />, admin: true },
  { link: '/BLPartList', value: 'Parts', icon: <FontAwesomeIcon icon={faCube} />, admin: true },
  { link: '/BLMinifigList', value: 'Minifigs', icon: <FontAwesomeIcon icon={faUser} />, admin: true },
  { link: '/OrderList', value: 'Orders', icon: <FontAwesomeIcon icon={faShoppingBasket} />, admin: true }
];


export enum Role {
  Admin = "Admin",
  Viewer = "Viewer"
};