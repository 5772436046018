import { NewsItem } from "../models/news.model";
import { axiosAWSNews } from "./axios.service";

export class NewsService { 
    public get = async (): Promise<NewsItem[]> => {
        const news = await axiosAWSNews().get('/news?q=LEGO');
        console.log(news);
        return news.data;
    }


}