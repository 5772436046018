import React, { ReactElement, useEffect, useState } from 'react';
import { Button, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, InputBase, Switch, TextField } from "@material-ui/core";
import { FunctionComponent } from "react";
import { Part } from '../../../models/part.model';
import { Add, Remove, Save } from '@material-ui/icons';
import styles from './PartDialog.module.scss';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import { Have } from '../../../models/have.model';

export const DialogType = {
  Create: 'Create',
  Edit: 'Edit'
}

interface IPartDialogProps {
  show: boolean,
  part: Part,
  handleShow: (openPartDialog: boolean) => void;
  handleSubmit: (amount: number, have: Have) => void;
  submitText: string
  haves: Have[];
  creating: boolean;
  isNeed: boolean;
  message: string;
}

export const PartDialog: FunctionComponent<IPartDialogProps> = (props: IPartDialogProps): ReactElement => {
  const [amount, setAmount] = useState(props.part.amount);
  const [have, setHave] = useState(new Have());
  const [addHave, setAddHave] = useState(true);

  useEffect(() => {
    setAmount(props.part.amount)
  }, [props.part.amount]);

  return (
    <Dialog onClose={() => { }} open={props.show} className={styles.partDialog}>
      <DialogTitle>{props.part.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <CardMedia
            style={{ objectFit: "contain" }}
            component="img"
            alt={props.part.name}
            height="140"
            image={props.part.imageUrl}
          />

        </DialogContentText>
        <div><span>Element ID:</span> {props.part.elementId}</div>
        <div><span>Part ID:</span> {props.part.no}</div>
        <div><span>Color:</span> {props.part.colorName}</div>
        
        <br />
        {props.part.alternates ? <div>Alternates: {props.part.alternates.split(';').join(', ').slice(0, -1)}</div> : <></>}

        <div className={styles.buttons}>
          <Button onClick={() => setAmount(amount - 1)}
            disabled={amount <= 0}
            variant="contained"
            color="secondary">
            <Remove />
          </Button>
          <InputBase
            inputProps={{
              style: {
                textAlign: 'center',
                fontWeight: "bold"
              }
            }}
            onChange={(event) => {
              setAmount(+event.target.value);
            }}
            value={amount}
            type="number"
            placeholder="Zoeken"
          />
          <Button onClick={() => setAmount(amount + 1)}
            variant="contained"
            color="secondary">
            <Add />
          </Button>
        </div>
        
        <div style={{color: "red", fontSize: "0.8em"}}>{props.message}</div>
        {
          props.isNeed ? props.submitText === DialogType.Create ?
            <><div style={{ marginLeft: "65px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={addHave}
                    onChange={(e, option) => { setAddHave(option) }}
                    color="primary"
                  />
                }
                label={addHave ? 'Missing from set' : 'Just want it'}
              />
            </div>
              <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                <Autocomplete
                  disabled={!addHave}
                  size="small"
                  onChange={(_, option) => {
                    if (option) {
                      setHave(option);
                    }
                  }}
                  options={props.haves}
                  getOptionLabel={(have: Have) => `${have.no} - ${have.name} - ${have.purchasePrice.toFixed(2).replace('.', ',')}`}
                  style={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Uncomplete set" variant="outlined" />}
                />
              </div></> : <></> : <></>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          props.handleSubmit(amount, have);
        }}
          disabled={amount <= 0 || (props.isNeed && props.creating && addHave && have.id === 0)}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Save />}>
          {props.submitText}
        </Button>
        <Button onClick={() => {
          props.handleShow(false);
        }} color="primary">Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
