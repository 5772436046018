import * as React from "react";

export interface IButtonProps {
    //text?: string;
    colorHex?: string;
    backgroundColorHex?: string;
    disabled?: boolean;
    onClick?: (event: any) => void;
    type?: ButtonType;
    style?: React.CSSProperties;
}

export interface IButtonState {

}

export enum ButtonType {
    Normal, Round, Reactangle
}