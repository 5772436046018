import { DataService } from "../data/DataService";
import { AlternateSet } from "../models/alternateSet.model";
import { Have } from "../models/have.model";
import { axiosLegoBricks, axiosRebrickable } from "./axios.service";

export class HaveService {

  private _dataService: DataService;

  constructor(dataService: DataService) {
    this._dataService = dataService;
  }

  public get = async (): Promise<Have[]> => {
    const response = await axiosLegoBricks().get("/Have");
    return response.data;
  };

  public search = async (search: string): Promise<Have[]> => {
    const response = await axiosRebrickable.get(`/sets/?search=${search}`);
    if (response.data.count > 0) {
      const searchResult = response.data.results;
      return searchResult.map(
        async (rebrickable: any) =>
          await this._dataService.rebrickableToHave(rebrickable, false, this.getAlternateSets)
      );
    }
    return new Array<Have>();
  };


  public getAlternateSets = async (no: string): Promise<AlternateSet[]> => {
    const reponseAlternates = await axiosRebrickable.get(
      `/sets/${no}/alternates/`
    );
    const alternates = reponseAlternates.data;
    return alternates.results.map(
      (alternate: any) =>
        new AlternateSet(
          0,
          alternate.name,
          alternate.num_parts,
          alternate.moc_url,
          alternate.moc_img_url,
          alternate.designer_name,
          alternate.designer_url
        )
    );
  };

  public save = async (have: Have): Promise<Have> => {
    const response = await axiosLegoBricks().post("/Have", have);
    return response.data;
  };

  public updateBuilt = async (id: number, closed: boolean) => {
    const response = await axiosLegoBricks().patch(`/Have/${id}`, [
      {
        value: closed,
        path: "/closed",
        op: "replace",
      },
    ]);
    return response.data;
  };

  public updatePrice = async (id: number, price: number): Promise<Have> => {
    const response = await axiosLegoBricks().patch(`/Have/${id}`, [
      {
        value: price,
        path: "/purchasePrice",
        op: "replace",
      },
    ]);
    return response.data;
  };

  public delete = async (id: number) => {
    const response = await axiosLegoBricks().delete(`/Have/${id}`);
    return response.data;
  };

  public sales = async () => {
    const response = await axiosLegoBricks().get(`/Have/sales`);
    return response.data;
  };

  public totalSpent = async () => {
    const response = await axiosLegoBricks().get("Have/totalSpent");
    return response.data;
  };

  public refreshAlternateSets = async (id: number, alternateSets: AlternateSet[]): Promise<Have> => {
    const response = await axiosLegoBricks().post(`/Have/${id}/refreshAlternates`, alternateSets);
    return response.data;
  };
}
