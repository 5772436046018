export class LocalStorageService {
    
    public setToken = (token: string) => {
        localStorage.setItem('token', token);
    }

    public getToken = (): string => {
        return localStorage.getItem('token') as string;
    }

    public removeToken = () => {
        localStorage.removeItem('token');
    }

    public getTokenObject() {
        const token = this.getToken();
        if (token) {
            const jwtData = this.getToken().split('.')[1];
            const decodedJwt = window.atob(jwtData);
            return JSON.parse(decodedJwt);
        } else {
            return null;
        }
    }
}