import { BottomNavigationAction, Container } from '@material-ui/core';
import BottomNavigation from '@material-ui/core/BottomNavigation/BottomNavigation';
import { Color } from "@material-ui/lab/Alert";
import React from 'react';
import { connect } from 'react-redux';
import { HashRouter, Link, Redirect, Route } from "react-router-dom";
import styles from './App.module.scss';
import BLMinifigList from './components/BrickLink/blMinifigList/BLMinifigList';
import BLPartList from './components/BrickLink/blPartList/BLPartList';
import BLSetList from './components/BrickLink/blSetList/BLSetList';
import OrderList from './components/BrickLink/orderList/OrderList';
import HaveList from './components/haveList/HaveList';
import Instructions from './components/instructions/Instructions';
import { LoadingBackground } from './components/loadingBackground/LoadingBackground';
import Login from './components/Login/Login';
import NavBar from './components/navbar/NavBar';
import NeedList from './components/needList/NeedList';
import NewsOverview from './components/news/NewsOverview';
import { Notification, NotificationStyle } from "./components/notification/Notification";
import PdfGenerator from './components/pdfGenerator/PdfGenerator';
import SaleContainer from './components/saleContainer/SaleContainer';
import WantList from './components/wantList/WantList';
import { navigationBrickLink, navigationLegoBricks, Role } from './data/Constants';
import AppContext, { AppContextProps } from './data/context';
import { AppView } from './data/Enums';
import { AppProps, AppState } from './IApp';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { mapDispatcherToProps, mapStateToProps } from './store/propMapping';


type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

class App extends React.Component<AppProps & ReduxType, AppState>{

  public context!: AppContextProps;
  public static contextType: React.Context<AppContextProps> = AppContext;

  public state: AppState = {
    nav: navigationLegoBricks[0].value,
    originPage: navigationLegoBricks[0].value,
    openNav: false,
    showNotification: false,
    notificationMessage: '',
    notificationStyle: NotificationStyle.Success,
    username: '',
    initFinished: false
  };

  // private updateTotalSpent = async () => {
  //   this.context.haveService.totalSpent().catch(() => {
  //     this.context.localStorageService.removeToken();
  //     this.props.SetUnAuthenticated();
  //     this.props.UnsetAdmin();
  //     this.context.setShowLoadingBackground(false);

  //   }).then((totalSpent: number) => this.setState({ totalSpent }));
  // }

  // private updateSaleBalance = async () => {
  //   this.context.saleService.getBalance()
  //     .then((balance: number) => { this.setState({ balance }) })
  //     .catch(() => {
  //       this.context.localStorageService.removeToken();
  //       this.props.SetUnAuthenticated();
  //       this.props.UnsetAdmin();
  //       this.context.setShowLoadingBackground(false);
  //     });
  // }

  private setNotification = (notificationMessage: string, notificationStyle: Color) => this.setState({ notificationMessage, notificationStyle, showNotification: true });

  public async componentDidMount() {
    const navObject = [...navigationLegoBricks, ...navigationBrickLink].find(n => window.location.hash.includes(n.link));
    const nav = navObject?.value;
    if (navigationBrickLink.map(x => x.value).includes(nav as string)) {
      this.context.setView(AppView.BrickLink);
    }

    const tokenObject = this.context.localStorageService.getTokenObject();
    const isvalid = tokenObject?.exp ? new Date(tokenObject.exp * 1000).getTime() > new Date().getTime() : false;
    if (isvalid) {
      this.props.SetAuthenticated();
      tokenObject.Role === Role.Admin ? this.props.SetAdmin() : this.props.UnsetAdmin();
      this.context.getTotalSpent();
      this.context.getSaleBalance();
    } else {
      this.props.SetUnAuthenticated();
      this.props.UnsetAdmin();
    }

    this.setState({ nav: nav ? nav : navigationLegoBricks[0].value, username: isvalid ? tokenObject.Username : '', initFinished: true });
  }

  public render(): React.ReactElement<AppProps & ReduxType> {
    return (this.state.initFinished ?
      <HashRouter>
        {this.context.showLoadingBackground && <LoadingBackground />}
        <Notification show={this.state.showNotification} message={this.state.notificationMessage} notificationStyle={this.state.notificationStyle} handleClose={() => this.setState({ showNotification: false })} />

        <NavBar
          username={this.state.username}
          unsetBottomNav={() => this.setState({ nav: '' })}
        />

        <Container style={{ marginTop: 75, marginBottom: 65 }}>
          <PrivateRoute component={
            <HaveList />} path="/HaveList" exact={true} redirectPath="/Login" condition={this.props.isAuthenticated && this.context.dataService.isView(this.context, AppView.LegoBricks)} />
          <PrivateRoute component={
            <WantList />} path="/WantList" exact={true} redirectPath="/Login" condition={this.props.isAuthenticated && this.context.dataService.isView(this.context, AppView.LegoBricks)} />
          <Route path="/" exact>
            {this.props.isAuthenticated ? <Redirect to="/HaveList" /> : <Redirect to="/Login" />}
          </Route>
          <PublicRoute component={
            <Login
              setUsername={(username: string) => this.setState({ username })}
              showNotification={this.setNotification} />} path="/Login" exact={false} redirectPath="/HaveList" />
          <PrivateRoute component={
            <NeedList
              setNotification={this.setNotification} />} path="/NeedList" exact={true} redirectPath="/Login" condition={this.props.isAuthenticated && this.context.dataService.isView(this.context, AppView.LegoBricks)}

          />
          {/* <PrivateRoute component={
            <SpareList
              spareService={this.props.spareService}
              needService={this.props.needService} />} path="/SpareList" exact={true} redirectPath="/Login" condition={this.props.isAuthenticated} /> */}
          {/* <PrivateRoute component={
            <MinifigList
              minifigService={this.props.minifigService}/>} path="/MinifigList" exact={true} redirectPath="/Login" condition={this.props.isAuthenticated} /> */}
          <PrivateRoute component={
            <Instructions />} path="/Instructions" exact={true} redirectPath="/Login" condition={this.props.isAuthenticated && this.context.dataService.isView(this.context, AppView.LegoBricks)} />
          <PrivateRoute component={
            <SaleContainer />} path="/SaleList" exact={true} redirectPath="/Login" condition={this.props.isAdmin && this.context.dataService.isView(this.context, AppView.LegoBricks)} />
          <PrivateRoute component={
            <PdfGenerator
              originPage={this.state.originPage}
            />} path="/PdfGenerator" exact={true} redirectPath="/Login" condition={this.props.isAuthenticated && this.context.dataService.isView(this.context, AppView.LegoBricks)} />

          <PrivateRoute component={
            <NewsOverview />} path="/News" exact={true} redirectPath="/Login" condition={this.props.isAuthenticated && this.context.dataService.isView(this.context, AppView.LegoBricks)} />

          <PrivateRoute component={
            <BLPartList
              setNotification={this.setNotification}
            />} path="/BLPartList" exact={true} redirectPath="/Login" condition={this.props.isAdmin && this.context.dataService.isView(this.context, AppView.BrickLink)} />


          <PrivateRoute component={
            <BLSetList
              setNotification={this.setNotification}
            />} path="/BLSetList" exact={true} redirectPath="/Login" condition={this.props.isAdmin && this.context.dataService.isView(this.context, AppView.BrickLink)} />


          <PrivateRoute component={
            <BLMinifigList
              setNotification={this.setNotification}
            />} path="/BLMinifigList" exact={true} redirectPath="/Login" condition={this.props.isAdmin && this.context.dataService.isView(this.context, AppView.BrickLink)} />

          <PrivateRoute component={
            <OrderList
            />} path="/OrderList" exact={true} redirectPath="/Login" condition={this.props.isAdmin && this.context.dataService.isView(this.context, AppView.BrickLink)} />
        </Container>

        {this.props.isAuthenticated && <BottomNavigation value={this.state.nav} onChange={(_, value) => this.setState({ nav: value, originPage: value })} className={styles.bottomNav}>
          {(this.context.view === AppView.LegoBricks ? navigationLegoBricks : navigationBrickLink).map((nav, index) =>
            (!nav.admin || this.props.isAdmin) && <BottomNavigationAction key={index}
              component={Link}
              to={nav.link}
              label={nav.value}
              value={nav.value}
              icon={nav.icon}
            />)}
        </BottomNavigation>}
      </HashRouter> : <></>
    );
  }
}

export default connect(mapStateToProps, mapDispatcherToProps)(App);
