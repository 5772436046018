import React from "react";
import { connect } from "react-redux";
import styles from "../../../assets/css/SetList.module.scss";
import AppContext, { AppContextProps } from "../../../data/context";
import { BLInventory } from "../../../models/blInventory.model";
import { mapStateToProps } from "../../../store/propMapping";
import GridView from "../../Generic/GridView";
import SearchBar from "../../searchBar/SearchBar";
import { SkeletonComponent } from "../../skeleton/Skeleton";
import { IBLMinifigListProps, IBLMinifigListState } from "./IBLMinifigList";

type ReduxType = ReturnType<typeof mapStateToProps>;

class BLMinifigList extends React.Component<IBLMinifigListProps & ReduxType, IBLMinifigListState> {

    public context!: AppContextProps;
    public static contextType: React.Context<AppContextProps> = AppContext;

    public state: IBLMinifigListState = {
        blMinifigs: [],
        filteredBLMinifigs: [],
        loaded: false
    };

    public async componentDidMount() {
        this.context.setNavTitle('Minifigs');
        //this.context.setShowLoadingBackground(true);
        const blMinifigs = await this.context.brickLinkService.getMinifigs();
        this.setState({ blMinifigs, filteredBLMinifigs: blMinifigs, loaded: true });
        //this.context.setShowLoadingBackground(false);
    }

    public render(): React.ReactElement<IBLMinifigListProps> {
        return (
            <div className={styles.setList}>

                <SearchBar
                    objects={this.state.blMinifigs}
                    filteredObjects={(filteredBLMinifigs: BLInventory[]) => { this.setState({ filteredBLMinifigs }) }}
                    filterAttr="no" />

                <div className={styles.setContainer}>
                    {
                        // this.state.loaded ? this.state.filteredHaves.length > 0 ?
                        this.state.loaded ? this.state.filteredBLMinifigs.map((blSet: BLInventory, index: number) =>
                            <GridView
                                blInventory={blSet}
                                key={index}
                            />) : <SkeletonComponent />
                        // : <div style={{ textAlign: 'center' }}><h3>No sets found!</h3></div> : <div style={{ textAlign: 'center' }}><h3>Loading...</h3></div>
                    }
                </div>
            </div>);
    }
}

export default connect(mapStateToProps,)(BLMinifigList);