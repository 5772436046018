import React from "react";
import { connect } from "react-redux";
import AppContext, { AppContextProps } from "../../data/context";
import { AlternateSet } from "../../models/alternateSet.model";
import { Have } from "../../models/have.model";
import { Need } from "../../models/need.model";
import { InstructionService } from "../../services/instruction.service";
import { mapStateToProps } from "../../store/propMapping";
import { ConfirmationDialog } from "../dialogs/ConfirmationDialog";
import { HaveoptionsDialog } from "../dialogs/haveOptionsDialog/HaveOptionsDialog";
import { PriceDialog } from "../dialogs/PriceDialog";
import { SearchDialog } from "../dialogs/searchDialog/SearchDialog";
import { DialogType, SetDialog } from "../dialogs/setDialog/SetDialog";
import SearchBar from "../searchBar/SearchBar";
import { SkeletonComponent } from "../skeleton/Skeleton";
import HaveItem from "./haveItem/HaveItem";
import styles from './HaveList.module.scss';
import { IHaveListProps, IHaveListState } from "./IHaveList";

type ReduxType = ReturnType<typeof mapStateToProps>;

class HaveList extends React.Component<IHaveListProps & ReduxType, IHaveListState> {

    public context!: AppContextProps;
    public static contextType: React.Context<AppContextProps> = AppContext;

    public state: IHaveListState = {
        ownHaves: new Array<Have>(),
        searchedHaves: [],
        have: new Have(),
        need: new Need(),
        showSetDialog: false,
        showSearchDialog: false,
        filteredHaves: new Array<Have>(),
        showConfirmationDialog: false,
        loaded: false,
        showPriceDialog: false,
        showHaveOptionsDialog: false,
        showNeedConfirmationDialog: false,
        instructions: []
    };

    private getHave = async (haveId: string) => {
        this.context.setShowLoadingBackground(true);
        const searchedHaves = await Promise.all(await this.context.haveService.search(haveId));
        searchedHaves.length === 1 ? this.setState({ have: searchedHaves[0], showSetDialog: true }) : this.setState({ searchedHaves, showSearchDialog: true });
        this.context.setShowLoadingBackground(false);
    }

    private getInstructions = async (no: string) => {
        const instructions = await new InstructionService().getInstructions(no);
        this.setState({ instructions });
    }

    public async componentDidMount() {
        this.context.setNavTitle('My Sets');
        //this.context.setShowLoadingBackground(true);
        const ownHaves = await this.context.haveService.get();
        this.setState({ ownHaves, filteredHaves: ownHaves, loaded: true });
        //this.context.setShowLoadingBackground(false);
    }

    public render(): React.ReactElement<IHaveListProps> {
        return (
            <div className={styles.haveList}>
                <SearchBar
                    objects={this.state.ownHaves}
                    filteredObjects={(filteredHaves: Have[]) => { this.setState({ filteredHaves }) }}
                    filterAttr="no"
                    searchString={(inputText: string) => this.getHave(inputText)} />



                <div className={styles.setContainer}>

                    {this.state.loaded ? this.state.filteredHaves.length > 0 ?
                        this.state.filteredHaves.map((have: Have) =>
                            <HaveItem
                                key={have.id}
                                have={have}
                                updateHave={async (closed: boolean) => {
                                    this.context.setShowLoadingBackground(true);
                                    await this.context.haveService.updateBuilt(have.id, closed);
                                    const ownHaves = this.state.ownHaves.map((h: Have) => h.id === have.id ? have : h);
                                    this.setState({ ownHaves });
                                    this.context.setShowLoadingBackground(false);
                                }}
                                deleteNeed={async (need: Need, have: Have) => {
                                    this.setState({ need, have, showNeedConfirmationDialog: true });
                                }}
                                updatePrice={() => {
                                    this.setState({ have, showPriceDialog: true })
                                }}
                                openOptionDialog={async (have: Have) => {
                                    this.getInstructions(have.no);
                                    this.setState({ have, showHaveOptionsDialog: true });
                                }}
                            />) : <div style={{ textAlign: 'center' }}><h3>No sets found!</h3></div> : <SkeletonComponent />}
                </div>

                <SetDialog
                    set={this.state.have}
                    show={this.state.showSetDialog}
                    submitText={DialogType.Register}
                    handleShow={(showSetDialog) => this.setState({ showSetDialog })}
                    handleSubmit={async (price?: number) => {
                        this.context.setShowLoadingBackground(true);
                        const have = { ...this.state.have };
                        if (price) {
                            have.purchasePrice = price;
                        }
                        if (have.alternateSets.length === 0) {
                            have.alternateSets = await this.context.haveService.getAlternateSets(have.no);
                        }
                        const savedHave = await this.context.haveService.save(have);
                        const morePieces = this.state.ownHaves.filter((have: Have) => have.partsQuantity > savedHave.partsQuantity);
                        const lessPieces = this.state.ownHaves.filter((have: Have) => have.partsQuantity <= savedHave.partsQuantity);
                        this.setState({ ownHaves: [...morePieces, savedHave, ...lessPieces], filteredHaves: [savedHave, ...this.state.filteredHaves] }, this.context.getTotalSpent);
                        this.context.setShowLoadingBackground(false);
                    }}
                    registerPrice={true} />
                <SearchDialog
                    show={this.state.showSearchDialog}
                    results={this.state.searchedHaves.map((have: Have) => ({ no: have.no, title: have.name, subtitle: have.themeName, imageUrl: have.imageUrl }))}
                    handleShow={(showSearchDialog) => this.setState({ showSearchDialog })}
                    handleSubmit={(resultNo: string) => {
                        const have = this.state.searchedHaves.find((have: Have) => have.no === resultNo);
                        if (have) {

                            this.setState({ have, showSetDialog: true });
                        }
                        this.setState({ showSearchDialog: false });
                    }} />

                <ConfirmationDialog
                    show={this.state.showConfirmationDialog}
                    title={`Deleting ${this.state.have.name}`}
                    handleConfirmation={async (confirm: boolean) => {
                        this.setState({ showConfirmationDialog: false });
                        if (confirm) {
                            this.context.setShowLoadingBackground(true);
                            const removedHave = await this.context.haveService.delete(this.state.have.id);
                            const reducedHaves = this.state.ownHaves.filter((have: Have) => have.id !== removedHave.id);
                            this.setState({ ownHaves: reducedHaves, filteredHaves: reducedHaves }, this.context.getTotalSpent);
                            this.context.setShowLoadingBackground(false);
                        }
                    }} ><p>Are you sure you want to delete this set with no {this.state.have.no} ?</p></ConfirmationDialog>

                <ConfirmationDialog
                    show={this.state.showNeedConfirmationDialog}
                    title={`Deleting ${this.state.need.name}`}
                    handleConfirmation={async (confirm: boolean) => {
                        this.setState({ showNeedConfirmationDialog: false });
                        if (confirm) {
                            this.context.setShowLoadingBackground(true);
                            const removedNeed = await this.context.needService.delete(this.state.need.id);
                            this.setState(prev => {
                                const haveIndexOwn = prev.ownHaves.findIndex((have: Have) => have.id === prev.have.id);
                                const haveIndexFiltered = prev.filteredHaves.findIndex((have: Have) => have.id === prev.have.id);

                                const otherNeeds = prev.ownHaves[haveIndexOwn].needs.filter((need: Need) => need.id !== removedNeed.id);
                                prev.ownHaves[haveIndexOwn].needs = otherNeeds;
                                prev.filteredHaves[haveIndexFiltered].needs = otherNeeds;

                                return ({ filteredHaves: prev.filteredHaves, ownHaves: prev.ownHaves });
                            });
                            // // const reducedNeeds = this.state.ownHaves.filter((have: Have) => have.id !== removedHave.id);
                            // // this.setState({ ownHaves: reducedHaves, filteredHaves: reducedHaves }, () => this.props.updateTotalSpent());
                            this.context.setShowLoadingBackground(false);
                        }
                    }} ><p>Are you sure you want to delete this missing pieces with no {this.state.need.elementId} ?</p></ConfirmationDialog>
                <PriceDialog
                    show={this.state.showPriceDialog}
                    title="Update Price"
                    content={<p>{`What was the purchase price of set ${this.state.have.no} ${this.state.have.name}?`}</p>}
                    handlePrice={async (price: number) => {
                        this.context.setShowLoadingBackground(true);
                        const updatedHave = await this.context.haveService.updatePrice(this.state.have.id, price);
                        this.setState(prev => {
                            const ownIndex = prev.ownHaves.findIndex((have: Have) => have.id === updatedHave.id);
                            const filteredIndex = prev.filteredHaves.findIndex((have: Have) => have.id === updatedHave.id);
                            let ownHaves = [...prev.ownHaves];
                            let filteredHaves = [...prev.filteredHaves];
                            ownHaves[ownIndex] = updatedHave;
                            filteredHaves[filteredIndex] = updatedHave;

                            return ({ ownHaves, filteredHaves });
                        }, this.context.getTotalSpent);
                        this.context.setShowLoadingBackground(false);
                    }}
                    handleClose={() => this.setState({ showPriceDialog: false })} />

                <HaveoptionsDialog
                    show={this.state.showHaveOptionsDialog}
                    instructions={this.state.instructions}
                    handleClose={() => this.setState({ showHaveOptionsDialog: false, instructions: [] })}
                    have={this.state.have}
                    deleteHave={() => this.setState({ showConfirmationDialog: true, showHaveOptionsDialog: false })}
                    deleteAlternate={async (id: number) => {
                        this.context.setShowLoadingBackground(true);
                        const deletedAlternate = await this.context.alternateSetService.delete(id);
                        this.setState(prev => {
                            const otherAlternates = prev.have.alternateSets.filter((alternateSet: AlternateSet) => alternateSet.id !== deletedAlternate.id);
                            const have = { ...prev.have };
                            have.alternateSets = otherAlternates;
                            const haveIndexOwn = prev.ownHaves.findIndex((ownHave: Have) => ownHave.id === have.id);
                            const haveIndexFiltered = prev.ownHaves.findIndex((filteredHave: Have) => filteredHave.id === have.id);
                            prev.ownHaves[haveIndexOwn] = have;
                            prev.filteredHaves[haveIndexFiltered] = have;
                            return ({ have, ownHaves: prev.ownHaves, filteredHaves: prev.filteredHaves });
                        })
                        this.context.setShowLoadingBackground(false);
                    }}
                    refreshAlternates={async () => {
                        this.context.setShowLoadingBackground(true);
                        const alternates = await this.context.haveService.getAlternateSets(this.state.have.no);
                        const have = await this.context.haveService.refreshAlternateSets(this.state.have.id, alternates);
                        this.setState(prev => {
                            const haveIndexOwn = prev.ownHaves.findIndex((ownHave: Have) => ownHave.id === have.id);
                            const haveIndexFiltered = prev.ownHaves.findIndex((filteredHave: Have) => filteredHave.id === have.id);
                            prev.ownHaves[haveIndexOwn] = have;
                            prev.filteredHaves[haveIndexFiltered] = have;
                            return ({ have, ownHaves: prev.ownHaves, filteredHaves: prev.filteredHaves });
                        })
                        this.context.setShowLoadingBackground(false);
                    }}
                    getPartsList={() => {
                        this.context.pdfService.partsInHave(this.state.have.no);
                    }}
                />
            </div>);
    }
}

export default connect(mapStateToProps,)(HaveList);