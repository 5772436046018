import { combineReducers, createStore } from 'redux';
import { IAdminState, IAuthState } from './types';
import { adminReducer, authReducer } from './reducer';

export interface IRootState {
    auth: IAuthState,
    admin: IAdminState
}

const store = createStore<IRootState, any, any, any>(
    combineReducers({
        auth: authReducer,
        admin: adminReducer
}));

export default store;