import { IAdminState, IAuthState, SET_ADMIN, SET_AUTHENTICATED, SET_UNAUTHENTICATED, UNSET_ADMIN } from './types';

const initAdmin: IAdminState = {
    isAdmin: false
};

export const adminReducer = (state: IAdminState = initAdmin, action: any): IAdminState => {
    switch (action.type) {
        case SET_ADMIN:
            return { isAdmin: true };
        case UNSET_ADMIN:
            return { isAdmin: false };
        default:
            return state;
    }
}

const initAuth: IAuthState = {
    isAuthenticated: false
};

export const authReducer = (state: IAuthState = initAuth, action: any): IAuthState => {
    switch (action.type) {
        case SET_AUTHENTICATED:
            return { isAuthenticated: true };
        case SET_UNAUTHENTICATED:
            return { isAuthenticated: false };
        default:
            return state;
    }
}