import { faCopy, faCrown, faCube, faExternalLinkAlt, faFilePdf, faMoneyBillAlt, faPlus, faShop, faStoreAlt, faUser, faUserCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppBar, Button, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from '@mui/lab/AdapterLuxon';
import { Box, ListItemButton, SpeedDial, SpeedDialAction, TextField } from "@mui/material";
import { DateTime } from "luxon";
import React, { FunctionComponent, useContext, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import AppContext, { AppContextProps } from "../../data/context";
import { AppView, Color } from "../../data/Enums";
import { Reward } from "../../models/reward.model";
import { mapDispatcherToProps, mapStateToProps } from "../../store/propMapping";
import GenericButton from "../button/Button";
import { ButtonType } from "../button/IButton";
import { ConfirmationDialog } from "../dialogs/ConfirmationDialog";
import InfoDialog from "../dialogs/InfoDialog";
import styles from './NavBar.module.scss';



type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

interface INavBarProps {
    username: string;
    unsetBottomNav: () => void;
}

const NavBar: FunctionComponent<INavBarProps & ReduxType & RouteComponentProps> = (props: ReduxType & INavBarProps & RouteComponentProps): React.ReactElement => {
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const context = useContext(AppContext) as AppContextProps;
    const [openDialog, setOpenDialog] = useState(false);
    const [reward, setReward] = useState(new Reward());
    const [openRewardDialog, setOpenRewardDialog] = useState(false);
    const [rewards, setRewards] = useState<Reward[]>([]);


    const getPoints = async () => {
        const rewardPoints = await context.rewardService.points();
        context.setRewardPoints(rewardPoints);
    }

    return (
        <>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => {
                        setOpen(true)
                        getPoints();
                    }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6">{context.navTitle}</Typography>
                </Toolbar>
            </AppBar>
            <Drawer open={open}>
                <List component="nav">
                    {props.isAuthenticated && <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={props.isAdmin ? faUserCog : faUser} />
                        </ListItemIcon>
                        <ListItemText primary={props.username} />
                    </ListItem>}
                    {props.isAdmin && context.totalSpent && context.dataService.isView(context, AppView.LegoBricks) && <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faMoneyBillAlt} />
                        </ListItemIcon>
                        <ListItemText primary={context.dataService.moneyFormatter(context.totalSpent)} secondary="Total Spent" />
                    </ListItem>}
                    {props.isAdmin && context.saleBalance && context.dataService.isView(context, AppView.LegoBricks) && <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faMoneyBillAlt} />
                        </ListItemIcon>
                        <ListItemText primary={context.dataService.moneyFormatter(context.saleBalance)} secondary="Sale Balance" />
                    </ListItem>}
                    {props.isAdmin && context.totalSpent && context.saleBalance && context.dataService.isView(context, AppView.LegoBricks) && <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faMoneyBillAlt} />
                        </ListItemIcon>
                        <ListItemText style={{ color: context.saleBalance - context.totalSpent > 0 ? "green" : "red" }} primary={context.dataService.moneyFormatter(context.saleBalance - context.totalSpent)} secondary="Investment" />
                    </ListItem>}
                    {props.isAuthenticated && context.dataService.isView(context, AppView.LegoBricks) && <ListItem button
                        onClick={() => {
                            history.push('/PdfGenerator');
                            props.unsetBottomNav();
                            setOpen(false);
                        }}>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faFilePdf} />
                        </ListItemIcon>
                        <ListItemText primary="Generate Pdf" />
                    </ListItem>}

                    {props.isAdmin && <ListItem button onClick={async () => {
                        const rewards = await context.rewardService.get();
                        setRewards(rewards);

                        //FIXME
                        setOpenRewardDialog(false);
                        setOpenRewardDialog(true);
                    }}>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faCrown} />
                        </ListItemIcon>
                        <ListItemText primary={`${context.rewardPoints} | ${context.dataService.moneyFormatter(context.rewardPoints / 150)}`} secondary="VIP Points" />
                        <ListItemButton>
                            <GenericButton
                                type={ButtonType.Round}
                                backgroundColorHex={Color.Green}

                                onClick={async (event) => {
                                    event.stopPropagation();
                                    setOpenDialog(true);
                                }}><FontAwesomeIcon icon={faPlus} /></GenericButton>
                        </ListItemButton>
                    </ListItem>}

                    {props.isAdmin && <ListItem button onClick={() => {
                        const isLegoBricks = context.view === AppView.LegoBricks;
                        context.setView(isLegoBricks ? AppView.BrickLink : AppView.LegoBricks);
                        history.push(isLegoBricks ? '/BLSetList' : '/HaveList');
                        setOpen(false);
                    }}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={context.view === AppView.LegoBricks ? faCube : faShop} />
                        </ListItemIcon>
                        <ListItemText primary={context.view === AppView.LegoBricks ? AppView.BrickLink : AppView.LegoBricks} style={{ padding: "5px 20px" }} />
                        <ListItemButton>
                            <Box>
                                <SpeedDial
                                    ariaLabel="SpeedDial basic example"
                                    className={styles.speedDial}
                                    icon={<FontAwesomeIcon icon={faStoreAlt} />}
                                    onClick={(event) => event.stopPropagation()}
                                >
                                  [
                                        <SpeedDialAction
                                            key="Copy"
                                            icon={<FontAwesomeIcon icon={faCopy} />}
                                            tooltipTitle="Copy"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                navigator.clipboard.writeText("https://store.bricklink.com/JelleCeulemans");
                                            }}
                                        />,

                                        <SpeedDialAction
                                            key="Store"
                                            icon={<FontAwesomeIcon icon={faExternalLinkAlt} />}
                                            tooltipTitle="Store"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                window.open('https://store.bricklink.com/JelleCeulemans', '_blank')
                                            }}
                                        />]
                                </SpeedDial>
                            </Box>
                            {/* <GenericButton
                                type={ButtonType.Round}
                                backgroundColorHex={Color.Green}

                                onClick={(event) => {

                                }}><FontAwesomeIcon icon={faPlus} /></GenericButton> */}
                        </ListItemButton>
                    </ListItem>}


                    {props.isAuthenticated && <ListItem button onClick={() => {
                        context.localStorageService.removeToken();
                        props.SetUnAuthenticated();
                        props.UnsetAdmin();
                        setOpen(false);
                    }}>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" style={{ padding: "5px 20px" }} />
                    </ListItem>}

                    <ListItem style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Button onClick={() => setOpen(false)}
                            variant="contained"
                            color="primary"
                            startIcon={<ArrowBack />}>Close</Button>
                    </ListItem>

                </List>
            </Drawer>

            <ConfirmationDialog
                title="Add VIP Points"
                show={openDialog}
                handleConfirmation={async (confirm: boolean) => {
                    if (confirm) {
                        await context.rewardService.save(reward);
                        getPoints();
                        setReward(new Reward());
                    }
                    setOpenDialog(false);
                }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", height: 190 }}>

                    <TextField style={{ width: "100%" }} label="Description" value={reward.description} type="text" variant="standard" onChange={(event) => {
                        const updateReward = { ...reward };
                        updateReward.description = event.target.value;
                        setReward(updateReward);
                    }} />
                    <LocalizationProvider dateAdapter={DateAdapter} >
                        <DatePicker
                            label="Date"
                            value={reward.date}
                            onChange={(newValue: any) => {
                                if (newValue) {
                                    const updateReward = { ...reward };
                                    const startOfDay = (newValue as DateTime).startOf('day').plus({ hours: 1 }).toJSDate();
                                    updateReward.date = startOfDay;
                                    setReward(updateReward);
                                }
                            }}
                            renderInput={(params) => <TextField {...params} variant="standard" style={{ width: "100%" }} />}
                        />

                    </LocalizationProvider>

                    <TextField style={{ width: "100%" }} label="Points" value={reward.amountPoints} type="number" variant="standard" onChange={(event) => {
                        const updateReward = { ...reward };
                        updateReward.amountPoints = +event.target.value;
                        setReward(updateReward);
                    }} />
                </div>
            </ConfirmationDialog>

            <InfoDialog
                show={openRewardDialog}
                title="VIP Punten"
            >
                <List dense={true}>

                    {rewards.map((reward: Reward, index: number) => <ListItem key={index} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" }}>
                        <div><b>{reward.amountPoints}: </b>{reward.description}</div>
                        <div>{DateTime.fromISO(reward.date as string).toFormat('dd/MM/yyyy')}</div>
                    </ListItem>)}

                </List>
            </InfoDialog>
        </>
    );
};

export default connect(mapStateToProps, mapDispatcherToProps)(withRouter(NavBar));