import { faBuilding, faHashtag, faMoneyBillAlt, faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, CardHeader, CardMedia, FormControlLabel, IconButton, List, ListItem, ListItemIcon, ListItemText, Switch } from "@material-ui/core";
import parse from 'html-react-parser';
import React, { FunctionComponent, useContext, useState } from 'react';
import { connect } from 'react-redux';
import AppContext from '../../data/context';
import { BLInventory } from '../../models/blInventory.model';
import { mapStateToProps } from '../../store/propMapping';
import styles from './GridView.module.scss';
import InfoDialog from '../dialogs/InfoDialog';
import { BLPriceOverview } from '../../models/blPrice.model';
import { OptionsDialog } from '../dialogs/OptionsDialog';
import { MoreVert } from '@material-ui/icons';
import { ConfirmationDialog } from '../dialogs/ConfirmationDialog';

interface IGridViewProps {
    blInventory: BLInventory;
    key: number;
    replaceInventory?: (inventory: BLInventory) => void;
    removeInventory?: (inventoryId: number) => void;
}

type ReduxType = ReturnType<typeof mapStateToProps>;

const GridView: FunctionComponent<IGridViewProps & ReduxType> = (props: IGridViewProps & ReduxType): React.ReactElement => {
    const context = useContext(AppContext);
    const [showPriceDialog, setShowPriceDialog] = useState(false);
    const [showOptionsDialog, setShowOptionsDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [price, setPrice] = useState(new BLPriceOverview());

    return (
        <>
            <Card key={props.key} className={styles.gridView}>
                <CardHeader
                    titleTypographyProps={{ variant: 'h6' }}
                    title={parse(props.blInventory.name)}
                    subheader={`${props.blInventory.condition}, ${props.blInventory.completeness}`}
                    action={
                        props.isAdmin && <IconButton onClick={() => {
                            setShowOptionsDialog(true);
                        }}>
                            <MoreVert />
                        </IconButton>
                    }
                />
                <CardMedia
                    style={{ objectFit: "contain" }}
                    component="img"
                    alt={props.blInventory.name}
                    height="140"
                    image={props.blInventory.imageUrl ?? '/images/noimage.png'}
                />
                <CardContent>
                    <List dense={true}>
                        <ListItem>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={!props.isAdmin}
                                        checked={props.blInventory.inStockRoom}
                                        onChange={async (_, value) => {
                                            const inventoryId = props.blInventory.inventoryId;
                                            const inventory = await (value ? context.brickLinkService.setInStockroom(inventoryId) : context.brickLinkService.removeFromStockroom(inventoryId));
                                            if (props.replaceInventory) {
                                                inventory.imageUrl = props.blInventory.imageUrl;
                                                props.replaceInventory(inventory);
                                            }
                                        }}
                                        color="primary"
                                    />
                                }
                                label="Stockroom"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <FontAwesomeIcon size="lg" icon={faBuilding} />
                            </ListItemIcon>
                            <ListItemText primary={props.blInventory.no} />
                        </ListItem>
                        {props.isAdmin && <ListItem button onClick={async () => {
                            const no = props.blInventory.no;
                            const price = await context.brickLinkService.getPrice(no);
                            setPrice(price);
                            setShowPriceDialog(false);
                            setShowPriceDialog(true);
                        }}>
                            <ListItemIcon>
                                <FontAwesomeIcon size="lg" icon={faMoneyBillAlt} />
                            </ListItemIcon>
                            <ListItemText primary={context.dataService.moneyFormatter(props.blInventory.price)} />
                            {/* <ListItemSecondaryAction>
                            <IconButton edge="end" onClick={() => props.updatePrice()} >
                                <Edit />
                            </IconButton>
                        </ListItemSecondaryAction> */}
                        </ListItem>}
                        <ListItem>
                            <ListItemIcon>
                                <FontAwesomeIcon size="lg" icon={faHashtag} />
                            </ListItemIcon>
                            <ListItemText primary={`${props.blInventory.quantity} available`} />
                        </ListItem>
                        {props.blInventory.description && <ListItem>
                            <ListItemIcon>
                                <FontAwesomeIcon size="lg" icon={faNoteSticky} />
                            </ListItemIcon>
                            <ListItemText primary={props.blInventory.description} />
                        </ListItem>}

                        {props.blInventory.remarks && <ListItem>
                            <ListItemIcon>
                                <FontAwesomeIcon size="lg" icon={faNoteSticky} />
                            </ListItemIcon>
                            <ListItemText primary={props.blInventory.remarks} />
                        </ListItem>}

                        {/* {props.have.alternateSets.length > 0 &&
                        <><ListItem button onClick={() => setOpenAlternates(!openAlternates)}>
                            <ListItemIcon>
                                <FontAwesomeIcon size="lg" icon={faLightbulb} />
                            </ListItemIcon>
                            <ListItemText primary="Alternates" />
                            {openAlternates ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                            <Collapse in={openAlternates} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {props.have.alternateSets.sort((b: AlternateSet, a: AlternateSet) => a.partsQuantity - b.partsQuantity).map((alternateSet: AlternateSet) => <ListItem key={alternateSet.id} button onClick={() => window.open(alternateSet.url, "_blank")}>
                                        <ListItemAvatar>
                                            <Avatar src={alternateSet.imageUrl} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={alternateSet.name}
                                            secondary={`${alternateSet.partsQuantity} piece(s)`}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" onClick={() => window.open(alternateSet.url, "_blank")} >
                                                <Launch />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>)}
                                </List>
                            </Collapse></>} */}
                        {/* {props.have.needs.length > 0 &&
                        <><ListItem button onClick={() => setOpenNeeds(!openNeeds)}>
                            <ListItemIcon>
                                <FontAwesomeIcon size="lg" icon={faStore} />
                            </ListItemIcon>
                            <ListItemText primary="Missing piece(s)" />
                            {openNeeds ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                            <Collapse in={openNeeds} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {props.have.needs.map((need: Need) => <ListItem key={need.id} button>
                                        <ListItemAvatar>
                                            <Avatar src={need.imageUrl} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={need.name}
                                            secondary={`${need.amount}x ${need.elementId}`}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" onClick={() => props.deleteNeed(need, props.have)} >
                                                <Delete />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>)}
                                </List>
                            </Collapse></>} */}
                    </List>
                </CardContent>
            </Card>

            <InfoDialog
                show={showPriceDialog}
                title="Prijzen">
                <h4>New</h4>
                <div><b>Minimum: </b>{context.dataService.moneyFormatter(price.newPrice.minimum)}</div>
                <div><b>Gemiddelde: </b>{context.dataService.moneyFormatter(price.newPrice.average)}</div>
                <div><b>Maximum: </b>{context.dataService.moneyFormatter(price.newPrice.maximum)}</div>

                <h4>Used</h4>
                <div><b>Minimum: </b>{context.dataService.moneyFormatter(price.usedPrice.minimum)}</div>
                <div><b>Gemiddelde: </b>{context.dataService.moneyFormatter(price.usedPrice.average)}</div>
                <div><b>Maximum: </b>{context.dataService.moneyFormatter(price.usedPrice.maximum)}</div>
            </InfoDialog>

            <OptionsDialog
                show={showOptionsDialog}
                blInventory={props.blInventory}
                handleClose={() => {
                    setShowOptionsDialog(false);
                }} delete={() => {
                    setShowOptionsDialog(false);
                    setShowDeleteDialog(true);
                }}
            ></OptionsDialog>

            <ConfirmationDialog
                show={showDeleteDialog}
                title={`Deleting ${props.blInventory.no}`}
                handleConfirmation={async (confirm: boolean) => {
                    setShowDeleteDialog(false);
                    if (confirm) {
                        context.setShowLoadingBackground(true);
                        await context.brickLinkService.deleteInventory(props.blInventory.inventoryId);
                        if (props.removeInventory) {
                            props.removeInventory(props.blInventory.inventoryId);
                        }
                        context.setShowLoadingBackground(false);
                    }
                }} ><p>Are you sure you want to delete this set with no {props.blInventory.no} ?</p></ConfirmationDialog>
        </>
    )
}

export default connect(mapStateToProps)(GridView);