import { Card, CardContent } from "@material-ui/core";
import { DateTime } from "luxon";
import React from "react";
import { connect } from "react-redux";
import AppContext, { AppContextProps } from "../../../data/context";
import { BLOrder } from "../../../models/blOrder.model";
import { mapStateToProps } from "../../../store/propMapping";
import { IOrderListProps, IOrderListState } from "./IOrderList";
import styles from './OrderList.module.scss';

type ReduxType = ReturnType<typeof mapStateToProps>;

class OrderList extends React.Component<IOrderListProps & ReduxType, IOrderListState> {

    public context!: AppContextProps;
    public static contextType: React.Context<AppContextProps> = AppContext;

    public state: IOrderListState = {
        orders: []
    };

    public async componentDidMount() {
        this.context.setNavTitle('Orders');
        this.context.setShowLoadingBackground(true);
        const orders = await this.context.brickLinkService.getOrders();
        this.setState({ orders });
        this.context.setShowLoadingBackground(false);
    }

    public render(): React.ReactElement<IOrderListProps> {
        return (
            <div className={styles.orderList}>
                {this.state.orders.map((order: BLOrder, index: number) => <Card key={index} className={styles.order}>
                    <CardContent className={styles.content}>
                        <div className={styles.w25}>{DateTime.fromISO(order.date).toFormat('dd LLLL yyyy')}</div>
                        <div className={styles.w15}>{order.status}</div>
                        <div className={styles.w10}>{order.itemAmount}</div>
                        <div className={styles.w15}>{this.context.dataService.moneyFormatter(order.subTotal)}</div>
                        <div className={styles.w15}>{this.context.dataService.moneyFormatter(order.grandTotal)}</div>
                        <div className={styles.w20}>{order.buyer}</div>
                    </CardContent>
                </Card>)}

            </div>);
    }
}

export default connect(mapStateToProps,)(OrderList);