export class Set {
    constructor(
        public id: number = 0,
        public no: string = '',
        public name: string = '',
        public release: number = 0,
        public partsQuantity: number = 0,
        public imageUrl: string = '',
        public themeName: string = '',
        public themeId: number = 0
    ) { }
}
