import jsPDF from "jspdf";
import React from "react";
import { renderToString } from "react-dom/server";
import { DataService } from "../data/DataService";
import { Have } from "../models/have.model";
import { Set } from "../models/set.model";
import { Need } from "../models/need.model";
import { Part } from "../models/part.model";
import { Sale } from "../models/sale.model";
import { Spare } from "../models/spare.model";
import { Want } from "../models/want.model";
import { axiosLegoBricks, axiosRebrickable } from "./axios.service";
import { DateTime } from 'luxon';


export class PdfService {

    private dataService: DataService;

    constructor(dataService: DataService) {
        this.dataService = dataService;
    }

    private tableDetailStyles: React.CSSProperties = {
        fontSize: 30,
        textAlign: "center",
        border: "1px solid #ddd",
        padding: "10px 0",
    }


    private tableHeaderStyles: React.CSSProperties = {
        padding: "10px 0 5px 0",
        fontSize: 35,
        backgroundColor: "green",
        textAlign: "center",
        color: "white",
        border: "1px solid #ddd"
    }

    private pdfDateTimeFormatting: string = 'ddMMyyyy-HHmmss';



    public haves = async (isAdmin: boolean) => {
        const response = await axiosLegoBricks().get('/Have');
        const haves: Have[] = response.data;

        const needPdfString = renderToString(<div>
            <table style={{
                borderCollapse: "collapse",
                width: 2232
            }}>
                <tr>
                    <th style={{ ...this.tableHeaderStyles, width: 360 }}>Image</th>
                    <th style={{ ...this.tableHeaderStyles, width: isAdmin ? 600 : 700 }}>Name</th>
                    <th style={{ ...this.tableHeaderStyles, width: isAdmin ? 200 : 250 }}>No</th>
                    <th style={{ ...this.tableHeaderStyles, width: isAdmin ? 300 : 340 }}>Theme</th>
                    <th style={{ ...this.tableHeaderStyles, width: isAdmin ? 200 : 214 }}>Pieces</th>
                    <th style={{ ...this.tableHeaderStyles, width: isAdmin ? 200 : 214 }}>Release</th>
                    <th style={{ ...this.tableHeaderStyles, width: isAdmin ? 172 : 214 }}>Closed</th>
                    {isAdmin && <th style={{ ...this.tableHeaderStyles, width: 200 }}>Price</th>}

                </tr>
                {haves.map((have: Have) =>
                    <tr key={have.id}>
                        <td style={this.tableDetailStyles}><img src={have.imageUrl} alt={have.name} width={300} /></td>
                        <td style={this.tableDetailStyles}>{have.name.replace(/'/g, '')}</td>
                        <td style={this.tableDetailStyles}>{have.no}</td>
                        <td style={this.tableDetailStyles}>{have.themeName}</td>
                        <td style={this.tableDetailStyles}>{have.partsQuantity}</td>
                        <td style={this.tableDetailStyles}>{have.release}</td>
                        <td style={this.tableDetailStyles}>{have.closed ? 'Yes' : 'No'}</td>
                        {isAdmin && <td style={this.tableDetailStyles}>{this.dataService.moneyFormatter(have.purchasePrice)}</td>}
                    </tr>)}

                {isAdmin &&
                    <tr>
                        <td style={{ ...this.tableDetailStyles, fontWeight: "bold" }}>Totaal</td>
                        <td style={this.tableDetailStyles}></td>
                        <td style={this.tableDetailStyles}></td>
                        <td style={this.tableDetailStyles}>{ }</td>
                        <td style={this.tableDetailStyles}></td>
                        <td style={this.tableDetailStyles}></td>
                        <td style={this.tableDetailStyles}></td>
                        <td style={{ ...this.tableDetailStyles, fontWeight: "bold" }}>{this.dataService.moneyFormatter(haves.map((have: Have) => have.purchasePrice).reduce((a, b) => a + b, 0))}</td>
                    </tr>}
            </table>
        </div>);
        
        let doc = new jsPDF('p', 'pt', [2480, 3508])
        const formatting = this.pdfDateTimeFormatting;
        await doc.html(needPdfString, {
            callback: function (doc) {
                doc.save(`Haves_${DateTime.now().toFormat(formatting)}`);
            },
            margin: [200, 124, 200, 124]
        });
        // return true;

    }

    public wants = async () => {
        const response = await axiosLegoBricks().get('/Want');
        const wants = response.data;

        const needPdfString = renderToString(<div>
            <table style={{
                borderCollapse: "collapse",
                width: 2232
            }}>
                <tr>
                    <th style={{ ...this.tableHeaderStyles, width: 835 }}>Name</th>
                    <th style={{ ...this.tableHeaderStyles, width: 355 }}>No</th>
                    <th style={{ ...this.tableHeaderStyles, width: 402 }}>Theme</th>
                    <th style={{ ...this.tableHeaderStyles, width: 320 }}>Pieces</th>
                    <th style={{ ...this.tableHeaderStyles, width: 320 }}>Release</th>

                </tr>
                {wants.map((want: Want) =>
                    <tr key={want.id}>
                        <td style={this.tableDetailStyles}>{want.name}</td>
                        <td style={this.tableDetailStyles}>{want.no}</td>
                        <td style={this.tableDetailStyles}>{want.themeName}</td>
                        <td style={this.tableDetailStyles}>{want.partsQuantity}</td>
                        <td style={this.tableDetailStyles}>{want.release}</td>
                    </tr>)}
            </table>
        </div>);
        let doc = new jsPDF('p', 'pt', [2480, 3508]);
        const formatting = this.pdfDateTimeFormatting;
        doc.html(needPdfString, {
            callback: function (doc) {
                doc.save(`Wants_${DateTime.now().toFormat(formatting)}`);
            },
            margin: [200, 124, 200, 124]
        });
    }

    public needs = async () => {
        const response = await axiosLegoBricks().get('/Need/notOrdered');
        const needs = response.data;

        const needPdfString = renderToString(<div>
            <table style={{
                borderCollapse: "collapse",
                width: 2232
            }}>
                <tr>
                    <th style={{ ...this.tableHeaderStyles, width: 300 }}>Image</th>
                    <th style={{ ...this.tableHeaderStyles, width: 640 }}>Name</th>
                    <th style={{ ...this.tableHeaderStyles, width: 240 }}>Element ID</th>
                    <th style={{ ...this.tableHeaderStyles, width: 250 }}>Part ID</th>
                    <th style={{ ...this.tableHeaderStyles, width: 240 }}>Color</th>
                    <th style={{ ...this.tableHeaderStyles, width: 162 }}>Amount</th>
                    <th style={{ ...this.tableHeaderStyles, width: 400 }}>Missing in</th>
                </tr>
                {needs.map((need: Need) =>
                    <tr key={need.id}>
                        <td style={this.tableDetailStyles}><img src={need.imageUrl} alt={need.name} /></td>
                        <td style={this.tableDetailStyles}>{need.name}</td>
                        <td style={this.tableDetailStyles}>{need.elementId}</td>
                        <td style={this.tableDetailStyles}>{need.no}</td>
                        <td style={this.tableDetailStyles}>{need.colorName}</td>
                        <td style={this.tableDetailStyles}>{need.amount}</td>
                        <td style={this.tableDetailStyles}>{need.have ? need.have.name : '/'}</td>
                    </tr>)}
            </table>
        </div>);
        let doc = new jsPDF('p', 'pt', [2480, 3508]);
        const formatting = this.pdfDateTimeFormatting;
        doc.html(needPdfString, {
            callback: function (doc) {
                doc.save(`Needs_${DateTime.now().toFormat(formatting)}`);
            },
            margin: [200, 124, 200, 124]
        });
    }

    public spares = async () => {
        const response = await axiosLegoBricks().get('/Spare');
        const spares = response.data;

        const needPdfString = renderToString(<div>
            <table style={{
                borderCollapse: "collapse",
                width: 2232
            }}>
                <tr>
                    <th style={{ ...this.tableHeaderStyles, width: 1000 }}>Name</th>
                    <th style={{ ...this.tableHeaderStyles, width: 432 }}>Element ID</th>
                    <th style={{ ...this.tableHeaderStyles, width: 400 }}>Part ID</th>
                    <th style={{ ...this.tableHeaderStyles, width: 400 }}>Color</th>
                </tr>
                {spares.map((spare: Spare) =>
                    <tr key={spare.id}>
                        <td style={this.tableDetailStyles}>{spare.name}</td>
                        <td style={this.tableDetailStyles}>{spare.elementId}</td>
                        <td style={this.tableDetailStyles}>{spare.no}</td>
                        <td style={this.tableDetailStyles}>{spare.colorName}</td>
                    </tr>)}
            </table>
        </div>);
        let doc = new jsPDF('p', 'pt', [2480, 3508]);
        const formatting = this.pdfDateTimeFormatting;
        doc.html(needPdfString, {
            callback: function (doc) {
                doc.save(`Spares_${DateTime.now().toFormat(formatting)}`);
            },
            margin: [200, 124, 200, 124]
        });
    }

    public sales = async () => {
        const response = await axiosLegoBricks().get('/Sale/forSale');
        const sales = response.data;

        const needPdfString = renderToString(<div>
            <table style={{
                borderCollapse: "collapse",
                width: 2232
            }}>
                <tr>
                    <th style={{ ...this.tableHeaderStyles, width: 722 }}>Name</th>
                    <th style={{ ...this.tableHeaderStyles, width: 250 }}>No</th>
                    <th style={{ ...this.tableHeaderStyles, width: 340 }}>Theme</th>
                    <th style={{ ...this.tableHeaderStyles, width: 230 }}>Pieces</th>
                    <th style={{ ...this.tableHeaderStyles, width: 230 }}>Release</th>
                    <th style={{ ...this.tableHeaderStyles, width: 230 }}>Closed</th>
                    <th style={{ ...this.tableHeaderStyles, width: 230 }}>Price</th>

                </tr>
                {sales.map((sale: Sale) =>
                    <tr key={sale.id}>
                        <td style={this.tableDetailStyles}>{sale.name}</td>
                        <td style={this.tableDetailStyles}>{sale.no}</td>
                        <td style={this.tableDetailStyles}>{sale.themeName}</td>
                        <td style={this.tableDetailStyles}>{sale.partsQuantity}</td>
                        <td style={this.tableDetailStyles}>{sale.release}</td>
                        <td style={this.tableDetailStyles}>{sale.closed ? 'Yes' : 'No'}</td>
                        <td style={this.tableDetailStyles}>{sale.purchasePrice}</td>
                    </tr>)}
            </table>
        </div>);
        let doc = new jsPDF('p', 'pt', [2480, 3508]);
        const formatting = this.pdfDateTimeFormatting;
        doc.html(needPdfString, {
            callback: function (doc) {
                doc.save(`Sales_${DateTime.now().toFormat(formatting)}`);
            },
            margin: [200, 124, 200, 124]
        });
    }

    public solds = async () => {
        const response = await axiosLegoBricks().get('/Sale/sold');
        const solds = response.data;

        const soldPdfString = renderToString(<div>
            <table style={{
                borderCollapse: "collapse",
                width: 2232
            }}>
                <tr>
                    <th style={{ ...this.tableHeaderStyles, width: 702 }}>Name</th>
                    <th style={{ ...this.tableHeaderStyles, width: 230 }}>No</th>
                    <th style={{ ...this.tableHeaderStyles, width: 300 }}>Theme</th>
                    <th style={{ ...this.tableHeaderStyles, width: 200 }}>Pieces</th>
                    <th style={{ ...this.tableHeaderStyles, width: 200 }}>Release</th>
                    <th style={{ ...this.tableHeaderStyles, width: 200 }}>Closed</th>
                    <th style={{ ...this.tableHeaderStyles, width: 400 }}>Sell / Buy Ratio</th>

                </tr>
                {solds.map((sold: Sale) =>
                    <tr key={sold.id}>
                        <td style={this.tableDetailStyles}>{sold.name}</td>
                        <td style={this.tableDetailStyles}>{sold.no}</td>
                        <td style={this.tableDetailStyles}>{sold.themeName}</td>
                        <td style={this.tableDetailStyles}>{sold.partsQuantity}</td>
                        <td style={this.tableDetailStyles}>{sold.release}</td>
                        <td style={this.tableDetailStyles}>{sold.closed ? 'Yes' : 'No'}</td>
                        <td style={this.tableDetailStyles}>{`${sold.sellingPrice.toFixed(2).replace('.', ',')} - ${sold.purchasePrice.toFixed(2).replace('.', ',')} = ${(sold.sellingPrice - sold.purchasePrice).toFixed(2).replace('.', ',')}`}</td>
                    </tr>)}
            </table>
        </div>);
        let doc = new jsPDF('p', 'pt', [2480, 3508]);
        const formatting = this.pdfDateTimeFormatting;
        doc.html(soldPdfString, {
            callback: function (doc) {
                doc.save(`Solds_${DateTime.now().toFormat(formatting)}`);
            },
            x: 124,
            y: 124
        });
    }

    public partsInHave = async (no: string) => {
        const results = await this.getAllRebrickableResults(`/sets/${no}/parts`);
        const mapped = results.map(async (x: any) => await this.rebrickableToPart(x));
        const parts = await Promise.all(mapped);

        const needPdfString = renderToString(<div>
            <table style={{
                borderCollapse: "collapse",
                width: 2232
            }}>
                <tr>
                    <th style={{ ...this.tableHeaderStyles, width: 250 }}>Image</th>
                    <th style={{ ...this.tableHeaderStyles, width: 720 }}>Name</th>
                    <th style={{ ...this.tableHeaderStyles, width: 300 }}>Element ID</th>
                    <th style={{ ...this.tableHeaderStyles, width: 320 }}>Part ID</th>
                    <th style={{ ...this.tableHeaderStyles, width: 300 }}>Color</th>
                    <th style={{ ...this.tableHeaderStyles, width: 232 }}>Amount</th>
                </tr>
                {parts.map((part: Part) =>
                    <tr key={part.id}>
                        <td style={this.tableDetailStyles}><img src={part.imageUrl} alt={part.name} width={200} /></td>
                        <td style={this.tableDetailStyles}>{part.name}</td>
                        <td style={this.tableDetailStyles}>{part.elementId}</td>
                        <td style={this.tableDetailStyles}>{part.no}</td>
                        <td style={this.tableDetailStyles}>{part.colorName}</td>
                        <td style={this.tableDetailStyles}>{part.amount}</td>
                    </tr>)}
            </table>
        </div>);
        let doc = new jsPDF('p', 'pt', [2480, 3508]);
        const formatting = this.pdfDateTimeFormatting;
        doc.html(needPdfString, {
            callback: function (doc) {
                doc.save(`Parts_In_${no}_${DateTime.now().toFormat(formatting)}`);
            },
            margin: [200, 124, 200, 124]
        });
    }

    public setsContainsPart = async (part: Part) => {

        const results = await this.getAllRebrickableResults(`/parts/${part.no}/colors/${part.colorId}/sets/`)
        const mapped = results.map(async (x: any) => await this.dataService.rebrickableToSet(x));
        const sets = await Promise.all(mapped);

        const setPdfString = renderToString(<div>
            <table style={{
                borderCollapse: "collapse",
                width: 2232
            }}>
                <tr>
                    <th style={{ ...this.tableHeaderStyles, width: 360 }}>Image</th>
                    <th style={{ ...this.tableHeaderStyles, width: 720 }}>Name</th>
                    <th style={{ ...this.tableHeaderStyles, width: 280 }}>No</th>
                    <th style={{ ...this.tableHeaderStyles, width: 350 }}>Theme</th>
                    <th style={{ ...this.tableHeaderStyles, width:  261 }}>Pieces</th>
                    <th style={{ ...this.tableHeaderStyles, width:  261 }}>Release</th>
                </tr>
                {sets.map((set: Set) =>
                    <tr key={set.no}>
                        <td style={this.tableDetailStyles}><img src={set.imageUrl} alt={set.name} width={300} /></td>
                        <td style={this.tableDetailStyles}>{set.name.replace(/'/g, '')}</td>
                        <td style={this.tableDetailStyles}>{set.no}</td>
                        <td style={this.tableDetailStyles}>{set.themeName}</td>
                        <td style={this.tableDetailStyles}>{set.partsQuantity}</td>
                        <td style={this.tableDetailStyles}>{set.release}</td>
                    </tr>)}
            </table>
        </div>);
        let doc = new jsPDF('p', 'pt', [2480, 3508]);
        const formatting = this.pdfDateTimeFormatting;
        await doc.html(setPdfString, {
            callback: function (doc) {
                doc.save(`Sets_Contains_Part_${part.no}_${DateTime.now().toFormat(formatting)}`);
            },
            margin: [200, 124, 200, 124]
        });

    }

    private rebrickableToPart = async (rebrickable: any) => {
        // const response = await axiosRebrickable.get(
        //   `/part_categories/${rebrickable.part.part_cat_id}/`
        // );

        //To many requests
        return new Part(
            0,
            rebrickable.part.part_num,
            rebrickable.part.name,
            "",
            rebrickable.element_id,
            rebrickable.part.part_img_url,
            rebrickable.color.name,
            rebrickable.color.id,
            "", //TODO IMPORT
            rebrickable.part.part_cat_id,
            rebrickable.quantity
        );
    };

    private getAllRebrickableResults = async (extendedUrl: string): Promise<any[]> => {
        try {
            const response = await axiosRebrickable.get(extendedUrl + '/?page_size=1000');
            let results: any[] = response.data.results;
            let next: string = response.data.next;

            while (next) {
                const subResponse = await axiosRebrickable.get(next.replace('https://rebrickable.com/api/v3/lego', ''));
                results = [...results, ...subResponse.data.results];
                next = subResponse.data.next;
            }

            return results;
        }
        catch (e) { return []; }
    }


}