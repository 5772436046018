export enum Color {
  Blue = "#0078d4",
  Green = "#75C836",
  Yellow = "#FFD059",
  Red = "#FF5959",
  Orange = "#FFA865",
  Purple = "#945FED",
  Teal = "#00D4AE",
  White = "#ffffff",
  Disabled = "#bbbbbb",
}

export enum AppView {
  LegoBricks = "LegoBricks",
  BrickLink = "BrickLink",
}

export enum SaleTypes {
  Sale = "sale",
  Sold = "sold",
}
