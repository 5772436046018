import React from 'react';
import styles from './LoadingBackground.module.scss';
import { CircularProgress } from "@material-ui/core";
import { FunctionComponent } from "react";



export const LoadingBackground: FunctionComponent<{}> = (): React.ReactElement => {

    return (
        <div className={styles.loadingBackground}>
          <CircularProgress size={80} color="secondary" />
        </div>
    )
}
