import React, { ReactElement, useState } from 'react';
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import { FunctionComponent } from "react";
import { Save } from '@material-ui/icons';
import styles from './SearchDialog.module.scss';

export const DialogType = {
    Register: 'Register',
    Edit: 'Edit'
}

interface Result {
    title: string;
    subtitle: string;
    imageUrl: string
    no: string;
}

interface ISearchDialog {
    show: boolean;
    results: Result[]
    handleShow: (showSearchDialog: boolean) => void;
    handleSubmit: (resultId: string) => void;
}

export const SearchDialog: FunctionComponent<ISearchDialog> = (props: ISearchDialog): ReactElement => {
    const [resultNo, setResultNo] = useState('');
    return (
        <Dialog onClose={() => { }} open={props.show} className={styles.searchDialog}>
            <DialogTitle>Search Results</DialogTitle>
            <DialogContent>

                {props.results.length > 0 ?
                    <List component="nav">
                {props.results.map((result, index) =>
                    <ListItem key={index}
                        button
                        selected={resultNo === result.no}
                        onClick={() => setResultNo(result.no)}>
                        <ListItemAvatar>
                            <Avatar src={result.imageUrl} />
                        </ListItemAvatar>

                        <ListItemText primary={result.title} secondary={result.subtitle} />
                    </ListItem>)}
            </List> : <h3>Geen items gevonden</h3>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    props.handleSubmit(resultNo);
                }}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<Save />}>
                    Choose
                </Button>
                <Button onClick={() => {
                    props.handleShow(false);
                }} color="primary">Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}
