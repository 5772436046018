import React, { FunctionComponent } from "react";
import { Redirect, Route } from "react-router-dom";

interface IPrivateRouteProps {
    component: React.ReactElement;
    path: string;
    exact: boolean;
    redirectPath: string;
    condition: boolean;
}

const PrivateRoute: FunctionComponent<IPrivateRouteProps> = (props: IPrivateRouteProps): React.ReactElement => {
    return (
        <Route path={props.path} exact={props.exact}>
            {props.condition ? props.component : <Redirect from={props.path} to={props.redirectPath} />}
        </Route>
    );
};

export default PrivateRoute;


