import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormControlLabel, Switch } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React from 'react';
import { connect } from 'react-redux';
import { navigationLegoBricks } from '../../data/Constants';
import AppContext, { AppContextProps } from '../../data/context';
import { mapStateToProps } from '../../store/propMapping';
import { IPdfGeneratorProps, IPdfGeneratorState } from './IPdfGenerator';

type ReduxType = ReturnType<typeof mapStateToProps>;

class PdfGenerator extends React.Component<IPdfGeneratorProps & ReduxType, IPdfGeneratorState> {

    public context!: AppContextProps;
    public static contextType: React.Context<AppContextProps> = AppContext;
  

    public state: IPdfGeneratorState = { 
        page: navigationLegoBricks[0].value, 
        isSale: true 
    };

    public componentDidMount() {
        this.context.setNavTitle('Generate PDF');        
        this.setState({ page: this.props.originPage });
    }

    public render(): React.ReactElement<IPdfGeneratorProps> {
        return (
            <div style={{ textAlign: "center" }}>
                <ToggleButtonGroup value={this.state.page} exclusive onChange={(_, value) => this.setState({ page: value })}>
                    {navigationLegoBricks.map((nav, index) => (!nav.admin || this.props.isAdmin) && <ToggleButton value={nav.value} key={index}>
                        {nav.icon}
                    </ToggleButton>)}
                </ToggleButtonGroup>

                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    {this.props.isAdmin && this.state.page === "Sale" && <FormControlLabel style={{ marginTop: "10px" }}
                        control={
                            <Switch
                                disabled={!this.props.isAdmin}
                                checked={this.state.isSale}
                                onChange={(_, value) => {
                                    this.setState({ isSale: value });
                                }}
                                color="primary"
                            />
                        }
                        label={this.state.isSale ? "Sales" : "Solds"}
                    />}
                    <Button
                        style={{ margin: "10px 0" }}
                        variant="contained"
                        color="secondary"
                        startIcon={<FontAwesomeIcon size="lg" icon={faFileDownload} />}
                        onClick={async () => {
                            this.context.setShowLoadingBackground(true);
                            if (this.state.page === "Have") {
                                await this.context.pdfService.haves(this.props.isAdmin);
                                this.context.setShowLoadingBackground(false);
                            } else if (this.state.page === "Want") {
                                await this.context.pdfService.wants();
                                this.context.setShowLoadingBackground(false);
                            } else if (this.state.page === "Need") {
                                await this.context.pdfService.needs();
                                this.context.setShowLoadingBackground(false);
                            } 
                            // else if (this.state.page === "Spare") {
                            //     await this.props.pdfService.spares();
                            //     this.context.setShowLoadingBackground(false);
                            // }
                            else if (this.state.page === "Sale" && this.props.isAdmin && this.state.isSale) {
                                await this.context.pdfService.sales();
                                this.context.setShowLoadingBackground(false);
                            } else if (this.state.page === "Sale" && this.props.isAdmin && !this.state.isSale) {
                                await this.context.pdfService.solds();
                                this.context.setShowLoadingBackground(false);
                            }
                            
                        }}>Generate</Button>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps,)(PdfGenerator);