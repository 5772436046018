import { DataService } from "../data/DataService";
import { Want } from "../models/want.model";
import { axiosLegoBricks, axiosRebrickable } from "./axios.service";

export class WantService {

	private _dataService: DataService;

  constructor(dataService: DataService) {
    this._dataService = dataService;
  }

	public get = async (): Promise<Want[]> => {
		const response = await axiosLegoBricks().get('/Want');
		return response.data;
	}

	public search = async (search: string): Promise<Want[]> => {
		const response = await axiosRebrickable.get(`/sets/?search=${search}`);
		if (response.data.count > 0) {
			const searchResult = response.data.results;
			return searchResult.map(async (rebrickable: any) => await this._dataService.rebrickableToWant(rebrickable));
		}
		return new Array<Want>();
	}

	

	save = async (want: Want): Promise<Want> => {
		const response = await axiosLegoBricks().post('/Want', want);
		return response.data;
	}

	delete = async (id: number) => {
		const response = await axiosLegoBricks().delete(`/Want/${id}`);
		return response.data;
	}
}

