import { BLInventory } from "../models/blInventory.model";
import { BLOrder } from "../models/blOrder.model";
import { BLPriceOverview } from "../models/blPrice.model";
import { axiosLegoBricks } from "./axios.service";

export class BrickLinkService {

	public getParts = async (): Promise<BLInventory[]> => {
		const response = await axiosLegoBricks().get(`/BrickLink/Inventory/Parts`);
		return response.data;
	}

	public getSets = async (): Promise<BLInventory[]> => {
		const response = await axiosLegoBricks().get(`/BrickLink/Inventory/Sets`);
		return response.data;
	}

	public getMinifigs = async (): Promise<BLInventory[]> => {
		const response = await axiosLegoBricks().get(`/BrickLink/Inventory/Minifigs`);
		return response.data;
	}

	public getPrice = async (no: string): Promise<BLPriceOverview> => {
		const response = await axiosLegoBricks().get(`/BrickLink/price/${no}`);
		return response.data;
	}

	public setInStockroom = async (inventoryId: number): Promise<BLInventory> => {
		const response = await axiosLegoBricks().post(`/BrickLink/inventory/InStockroom?id=${inventoryId}`);
		return response.data;
	}

	public removeFromStockroom = async (inventoryId: number): Promise<BLInventory> => {
		const response = await axiosLegoBricks().post(`/BrickLink/inventory/RemoveStockroom?id=${inventoryId}`);
		return response.data;
	}

	public deleteInventory = async (inventoryId: number): Promise<void> => {
		await axiosLegoBricks().delete(`/BrickLink/inventory/${inventoryId}`);
	}

	public getOrders = async (): Promise<BLOrder[]> => {
		const response = await axiosLegoBricks().get(`/BrickLink/orders`);
		return response.data;
	}
}