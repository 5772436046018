export class Part {
    constructor(
        public id: number = 0,
        public no: string = '',
        public name: string = '',
        public alternates: string = '',
        public elementId: string = '',
        public imageUrl: string = '',
        public colorName: string = '',
        public colorId: number = 0,
        public categoryName: string = '',
        public categoryId: number = 0,
        public amount: number = 0
    ) { }
}