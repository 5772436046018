import React, { ReactElement } from 'react';
import { Avatar, Button, CardHeader, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { FunctionComponent } from "react";
import { Delete, Launch, Refresh } from '@material-ui/icons';
import { Have } from '../../../models/have.model';
import { AlternateSet } from '../../../models/alternateSet.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBuilding, faChartLine, faCube, faListOl, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons';
import { Instruction } from '../../../models/instruction.model';


interface IHaveoptionsDialog {
  show: boolean;
  have: Have;
  handleClose: () => void;
  deleteHave: () => void;
  deleteAlternate: (alternateId: number) => void;
  refreshAlternates: () => void;
  getPartsList: () => void;
  instructions: Instruction[];
}

export const HaveoptionsDialog: FunctionComponent<IHaveoptionsDialog> = (props: IHaveoptionsDialog): ReactElement => {


  return (
    <Dialog open={props.show}>
      <CardHeader
        titleTypographyProps={{ variant: 'h6' }}
        title={props.have.name}
        subheader={`${props.have.themeName}, ${props.have.release}`}
        action={
          <>
            <IconButton onClick={props.refreshAlternates}>
              <Refresh />
            </IconButton>
            <IconButton onClick={() => {
              props.deleteHave();
            }}>
              <Delete />
            </IconButton>
          </>
        }
      />
      <DialogContent>
        <DialogContentText>

          <CardMedia
            style={{ objectFit: "contain" }}
            component="img"
            alt={props.have.name}
            height="150"
            image={props.have.imageUrl}
          />

        </DialogContentText>
        <List>
        <ListItem button onClick={() => window.open(`https://www.bricklink.com/v2/catalog/catalogitem.page?S=${props.have.no}`, "_blank")}>
            <ListItemIcon>
              <FontAwesomeIcon size="lg" icon={faCube} />
            </ListItemIcon>
            <ListItemText
              primary="BrickLink"
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => window.open(`https://www.brickwatch.net/nl-BE/set/${props.have.no.split('-')[0]}`, "_blank")} >
                <Launch />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button onClick={() => window.open(`https://www.brickwatch.net/nl-BE/set/${props.have.no.split('-')[0]}`, "_blank")}>
            <ListItemIcon>
              <FontAwesomeIcon size="lg" icon={faMoneyBillAlt} />
            </ListItemIcon>
            <ListItemText
              primary="Brickwatch"
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => window.open(`https://www.brickwatch.net/nl-BE/set/${props.have.no.split('-')[0]}`, "_blank")} >
                <Launch />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button onClick={() => window.open(`https://www.brickeconomy.com/search?query=${props.have.no}`, "_blank")}>
            <ListItemIcon>
              <FontAwesomeIcon size="lg" icon={faChartLine} />
            </ListItemIcon>
            <ListItemText
              primary="BrickEconomy"
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => window.open(`https://www.brickeconomy.com/search?query=${props.have.no}`, "_blank")} >
                <Launch />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button onClick={() => window.open(`https://rebrickable.com/sets/${props.have.no}`, "_blank")}>
            <ListItemIcon>
              <FontAwesomeIcon size="lg" icon={faBuilding} />
            </ListItemIcon>
            <ListItemText
              primary="Rebrickable"
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => window.open(`https://rebrickable.com/sets/${props.have.no}`, "_blank")} >
                <Launch />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem button onClick={props.getPartsList}>
            <ListItemIcon>
              <FontAwesomeIcon size="lg" icon={faListOl} />
            </ListItemIcon>
            <ListItemText
              primary="Parts list"
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={props.getPartsList} >
                <Launch />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>

          {props.instructions.map((instruction: Instruction, index: number) => <ListItem key={index} button onClick={() => window.open(instruction.url, "_blank")}>
            <ListItemIcon>
              <FontAwesomeIcon size="lg" icon={faBook} />
            </ListItemIcon>
            <ListItemText
              primary={instruction.description}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => window.open(instruction.url, "_blank")} >
                <Launch />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>)}

         

        </List>

        <List component="div" disablePadding>
          {props.have.alternateSets.sort((b: AlternateSet, a: AlternateSet) => a.partsQuantity - b.partsQuantity).map((alternateSet: AlternateSet) => <ListItem key={alternateSet.id} button onClick={() => window.open(alternateSet.url, "_blank")}>
            <ListItemAvatar>
              <Avatar src={alternateSet.imageUrl} />
            </ListItemAvatar>
            <ListItemText
              primary={alternateSet.name}
              secondary={`${alternateSet.partsQuantity} piece(s)`}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => props.deleteAlternate(alternateSet.id)} >
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>)}
        </List>

        {/* {props.registerPrice && <TextField label="Price" type="number" inputProps={{ step: 0.01 }} onChange={(event) => setPrice(+event.target.value)} />} */}

      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          props.handleClose();
        }} color="primary">Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
