import { AlternateSet } from "../models/alternateSet.model";
import { Have } from "../models/have.model";
import { Need } from "../models/need.model";
import { Set } from "../models/set.model";
import { Spare } from "../models/spare.model";
import { Want } from "../models/want.model";
import { axiosRebrickable } from "../services/axios.service";
import { ThemeService } from "../services/theme.service";
import { AppContextProps } from "./context";
import { AppView } from "./Enums";

export class DataService {
  private _themeService: ThemeService;

  constructor(themeService: ThemeService) {
    this._themeService = themeService;
  }

  public rebrickableToSet = async (rebrickable: any) => {
    return new Set(
      0,
      rebrickable.set_num,
      rebrickable.name,
      rebrickable.year,
      rebrickable.num_parts,
      rebrickable.set_img_url,
      await this._themeService.getThemeNameById(rebrickable.theme_id),
      rebrickable.theme_id
    );
  };


  public rebrickableToHave = async (rebrickable: any, addAlternateSets: boolean, getAlternateSets: (no: string) => Promise<AlternateSet[]>) => {
    const have: Have = {
      ...(await this.rebrickableToSet(rebrickable)),
      closed: true,
      purchasePrice: 0.0,
      needs: [],
      alternateSets: addAlternateSets
        ? await getAlternateSets(rebrickable.set_num)
        : new Array<AlternateSet>(),
    };

    return have;
  };


  public rebrickableToNeed = async (rebrickable: any) => {
    const response = await axiosRebrickable.get(
      `/part_categories/${rebrickable.part.part_cat_id}/`
    );
    return new Need(
      0,
      rebrickable.part.part_num,
      rebrickable.part.name,
      rebrickable.part.alternates.join(";"),
      rebrickable.element_id,
      rebrickable.element_img_url,
      rebrickable.color.name,
      rebrickable.color.id,
      response.data.name,
      rebrickable.part.part_cat_id,
      0,
      false,
      new Have()
    );
  };

  public rebrickableToSpare = async (rebrickable: any) => {
    const response = await axiosRebrickable.get(`/part_categories/${rebrickable.part.part_cat_id}/`);
    return new Spare(
      0,
      rebrickable.part.part_num,
      rebrickable.part.name,
      rebrickable.part.alternates.join(';'),
      rebrickable.element_id,
      rebrickable.element_img_url,
      rebrickable.color.name,
      rebrickable.color.id,
      response.data.name,
      rebrickable.part.part_cat_id,
      0
    )
  }

  public rebrickableToWant = async (rebrickable: any) => {
    return new Want(
      0,
      rebrickable.set_num,
      rebrickable.name,
      rebrickable.year,
      rebrickable.num_parts,
      rebrickable.set_img_url,
      await this._themeService.getThemeNameById(rebrickable.theme_id),
      rebrickable.theme_id);
  }

  // public mapToNewsArticle = (item: any) => {
  //   return new NewsArticle(
  //     item.title,
  //     item.description,
  //     item.url,
  //     item.urlToImage,
  //     item.source.name,
  //     new Date(item.publishedAt)
  //   );
  // }

  public moneyFormatter = (value: number) => '€' + value.toFixed(2).replace('.', ',');


  public isView = (context: AppContextProps,view: AppView) => context.view === view;

  public replaceObjectByProperty = (items: any[], property: string, replaceItem: any) => {
    return items.map(x => x[property] === replaceItem[property] ? replaceItem : x);
    // const index = items.findIndex(x => x[property] === replaceItem[property]);
    // items[index] = replaceItem;
    // return items;
  }
}
