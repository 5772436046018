import { AlternateSet } from "./alternateSet.model";
import { Need } from "./need.model";
import { Set } from "./set.model";
export class Have extends Set {
    constructor(
        public id: number = 0,
        public no: string = '',
        public name: string = '',
        public release: number = 0,
        public partsQuantity: number = 0,
        public imageUrl: string = '',
        public themeName: string = '',
        public themeId: number = 0,
        public closed: boolean = true,
        public purchasePrice: number = 0,
        public needs: Need[] = [],
        public alternateSets: AlternateSet[] = []
    ) {
        super(id, no, name, release, partsQuantity, imageUrl, themeName, themeId);
    }
}