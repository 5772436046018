import React, { useContext } from 'react';
import { Card, CardContent, CardHeader, CardMedia, FormControlLabel, List, ListItem, ListItemIcon, ListItemText, Switch } from "@material-ui/core";
import { FunctionComponent } from "react";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faHashtag, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './SoldItem.module.scss';
import { mapStateToProps } from '../../../../store/propMapping';
import { Sale } from '../../../../models/sale.model';
import AppContext from '../../../../data/context';

interface ISoldItemProps {
    sold: Sale;
}

type ReduxType = ReturnType<typeof mapStateToProps>;

const SoldItem: FunctionComponent<ISoldItemProps & ReduxType> = (props: ISoldItemProps & ReduxType): React.ReactElement => {
    const context = useContext(AppContext);

    return (
        <Card key={props.sold.no} className={styles.soldItem}>
            <CardHeader
                titleTypographyProps={{ variant: 'h6' }}
                title={props.sold.name}
                subheader={`${props.sold.themeName}, ${props.sold.release}`}
            // action={
            //     props.isAdmin && <IconButton onClick={() => { props.deleteSold(props.sold) }}>
            //         <Delete />
            //     </IconButton>
            // }
            />
            <CardMedia
                style={{ objectFit: "contain" }}
                component="img"
                alt={props.sold.name}
                height="140"
                image={props.sold.imageUrl}
            />
            <CardContent>
                <List dense={true}>
                    <ListItem>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={true}
                                    checked={props.sold.closed}
                                    color="primary"
                                />
                            }
                            label={props.sold.closed ? "Closed" : "Opened"}
                        />
                        </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faBuilding} />
                        </ListItemIcon>
                        <ListItemText primary={props.sold.no} />
                    </ListItem>
                    {props.isAdmin && <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faMoneyBillAlt} />
                        </ListItemIcon>
                        <ListItemText primary={`${context.dataService.moneyFormatter(props.sold.sellingPrice)} - ${context.dataService.moneyFormatter(props.sold.purchasePrice)} = ${context.dataService.moneyFormatter(props.sold.sellingPrice - props.sold.purchasePrice)}`} />
                    </ListItem>}
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faHashtag} />
                        </ListItemIcon>
                        <ListItemText primary={`${props.sold.partsQuantity} piece(s)`} />
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    )
}

export default connect(mapStateToProps)(SoldItem);