import React from 'react';
import { Button, Card, CardContent, CardHeader, CardMedia, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { FunctionComponent } from "react";
import { Delete, Launch } from '@material-ui/icons';
import { mapStateToProps } from '../../../store/propMapping';
import { connect } from 'react-redux';
import { Want } from '../../../models/want.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faChartLine, faHashtag, faLaughBeam, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './WantItem.module.scss';

interface IWantItemProps {
    want: Want;
    deleteWant: (want: Want) => void;
    makeHave: (want: Want) => void;
}

type ReduxType = ReturnType<typeof mapStateToProps>;

const WantItem: FunctionComponent<IWantItemProps & ReduxType> = (props: IWantItemProps & ReduxType): React.ReactElement => {
    return (
        <Card key={props.want.no} className={styles.wantItem}>
            <CardHeader
                titleTypographyProps={{ variant: 'h6' }}
                title={props.want.name}
                subheader={`${props.want.themeName}, ${props.want.release}`}
                action={
                    props.isAdmin && <IconButton onClick={() => props.deleteWant(props.want)}>
                        <Delete />
                    </IconButton>
                }
            />
            <CardMedia
                style={{ objectFit: "contain" }}
                component="img"
                alt={props.want.name}
                height="140"
                image={props.want.imageUrl}
            />
            <CardContent>
                <List dense={true}>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faBuilding} />
                        </ListItemIcon>
                        <ListItemText primary={props.want.no} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faHashtag} />
                        </ListItemIcon>
                        <ListItemText primary={`${props.want.partsQuantity} piece(s)`} />
                    </ListItem>
                    <ListItem button onClick={() => window.open(`https://www.brickwatch.net/nl-BE/set/${props.want.no.split('-')[0]}`, "_blank")}>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faMoneyBillAlt} />
                        </ListItemIcon>
                        <ListItemText
                            primary="Brickwatch"
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" onClick={() => window.open(`https://www.brickwatch.net/nl-BE/set/${props.want.no.split('-')[0]}`, "_blank")} >
                                <Launch />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem button onClick={() => window.open(`https://www.brickeconomy.com/search?query=${props.want.no}`, "_blank")}>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faChartLine} />
                        </ListItemIcon>
                        <ListItemText
                            primary="BrickEconomy"
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" onClick={() => window.open(`https://www.brickeconomy.com/search?query=${props.want.no}`, "_blank")} >
                                <Launch />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>

                </List>
                {props.isAdmin && <div style={{ textAlign: "center" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.makeHave(props.want)}
                        startIcon={<FontAwesomeIcon size="lg" icon={faLaughBeam} />}>Own</Button>
                </div>}
            </CardContent>
        </Card>
    )
}

export default connect(mapStateToProps)(WantItem);