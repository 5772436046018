
//import { DefaultButton } from 'office-ui-fabric-react';
import * as React from 'react';
import { IButtonState, IButtonProps, ButtonType } from './IButton';
import styles from './Button.module.scss';
import { Button } from '@material-ui/core';
import { Color } from '../../data/Enums';

export default class GenericButton extends React.Component<IButtonProps, IButtonState> {
    private RoundStyles: React.CSSProperties = {
        borderRadius: "50%",
        minWidth: 0,
        height: 40,
        width: 40,
        padding: 0
    };

    private RectangleStyles: React.CSSProperties = {
        minWidth: 0,
        height: 60,
        width: 60
    };

    private NormalStyles: React.CSSProperties = {

    };

    private getExtraStyles = (type: ButtonType | undefined): React.CSSProperties => {
        const extraStyles = {
            [ButtonType.Round]: this.RoundStyles,
            [ButtonType.Reactangle]: this.RectangleStyles,
            [ButtonType.Normal]: this.NormalStyles
        };

        const selectedStyle = extraStyles[type ?? ButtonType.Normal];

        return selectedStyle;
    }


    public render(): React.ReactElement<IButtonProps> {
        const standardStyle: React.CSSProperties = {
            backgroundColor: this.props.disabled ? Color.Disabled : this.props.backgroundColorHex || Color.Blue,
            color: this.props.colorHex || Color.White,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center"
        };
        return (
            <Button
                style={{ ...standardStyle, ...this.getExtraStyles(this.props.type), ...this.props.style }}
                className={styles.button}
                disabled={this.props.disabled}
                onClick={this.props.onClick}>{this.props.children}</Button>
        );
    }
}
