import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { FunctionComponent } from "react";

interface IPriceDialogProps {
    show: boolean;
    title: string;
    content: React.ReactElement;
    handlePrice: (price: number) => void;
    handleClose: () => void;
}

export const PriceDialog: FunctionComponent<IPriceDialogProps> = (props: IPriceDialogProps): React.ReactElement => {
    const [price, setPrice] = useState(0);

    return (
        <Dialog
            open={props.show}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                {props.content}
                <TextField label="Price" type="number" inputProps={{ step: 0.01 }} onChange={(event) => setPrice(+event.target.value)} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => props.handleClose()}>Cancel</Button>
                <Button disabled={price <= 0} onClick={() => {
                    props.handlePrice(price);
                    props.handleClose()
                }} color="primary" autoFocus>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}
