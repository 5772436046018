import { Have } from "./have.model";
import { Part } from "./part.model";

export class Need extends Part {
    constructor(
        public id: number = 0,
        public no: string = '',
        public name: string = '',
        public alternates: string = '', 
        public elementId: string = '',
        public imageUrl: string = '',
        public colorName: string = '',
        public colorId: number = 0,
        public categoryName: string = '',
        public categoryId: number = 0,
        public amount: number = 0,
        public checked: boolean = false,
        public have: Have = new Have()
    ) {
        super(id, no, name, alternates, elementId, imageUrl, colorName, colorId, categoryName, categoryId, amount);
    }
}