export class BLPrice {
    constructor(
        public minimum: number = 0,
        public maximum: number = 0,
        public average: number = 0
    ) { }
}

export class BLPriceOverview {
    constructor(
        public newPrice: BLPrice = new BLPrice(),
        public usedPrice: BLPrice = new BLPrice(),
    ) { }
}
