import { CardMedia, List, ListItem, Typography } from "@material-ui/core";
import React from "react";
import styles from '../../../assets/css/PartList.module.scss';
import { connect } from "react-redux";
import AppContext, { AppContextProps } from "../../../data/context";
import { mapStateToProps } from "../../../store/propMapping";
import { IBLPartListProps, IBLPartListState } from "./IBLPartList";
import { BLInventory } from "../../../models/blInventory.model";

type ReduxType = ReturnType<typeof mapStateToProps>;

class BLPartList extends React.Component<IBLPartListProps & ReduxType, IBLPartListState> {

    public context!: AppContextProps;
    public static contextType: React.Context<AppContextProps> = AppContext;

    public state: IBLPartListState = {
        blParts: [],
        filteredBlParts: [],
        searchString: '',
    };

    public async componentDidMount() {
        this.context.setNavTitle('Missing parts');
        this.context.setShowLoadingBackground(true);
        const blParts = await this.context.brickLinkService.getParts();
        this.setState({ blParts, filteredBlParts: blParts });
        this.context.setShowLoadingBackground(false);
    }

    // private searchPart = async () => {
    //     this.context.setShowLoadingBackground(true);
    //     const need = await this.context.needService.byElementId(this.state.searchString);
    //     this.context.setShowLoadingBackground(false);
    //     need ? this.setState({ need, openNeedDialog: true, dialogType: DialogType.Create, creating: true }) : this.setState({ openConfirm1: true });
    // }

    public render(): React.ReactElement<IBLPartListProps> {

        return (
            <div className={styles.partList}>
                    {/* <SearchBar
                        objects={this.state.needs}
                        filteredObjects={(filteredNeeds: Need[]) => this.setState({ filteredNeeds })}
                        filterAttr="elementId"
                        searchString={(inputText: string) => { this.setState({ searchString: inputText }, () => this.searchPart()) }} /> */}
                    {this.state.filteredBlParts.length === 0 && this.state.blParts.length > 0 && <div style={{ textAlign: 'center' }}><h4>Not present in your part's need list!</h4></div>}
                    <List style={{ overflow: 'auto', marginBottom: '56px' }} dense={false}>
                        {
                            this.state.filteredBlParts.map((blPart: BLInventory, index: number) => <ListItem key={index}>
                                <div className={styles.listItem}>
                                    <CardMedia
                                        style={{ objectFit: "contain", width: '20%' }}
                                        component="img"
                                        alt={blPart.name}
                                        height="70"
                                        image={blPart.imageUrl ?? '/images/noimage.png'}
                                    />
                                    <div className={styles.information}>
                                        <div className={styles.name}>{blPart.name}</div>
                                        <Typography variant="subtitle2" gutterBottom>{"category"}</Typography>
                                        {/* <div><span>Element ID:</span> {need.elementId}</div> */}
                                        <div className={styles.property}><span>Part ID:</span> {blPart.no}</div>
                                        <div className={styles.property}><span>Color:</span> {blPart.colorName}</div>
                                        <div className={styles.property}><span>Quantity:</span> {blPart.quantity}</div>
                                        <div className={styles.property}><span>Price:</span> {this.context.dataService.moneyFormatter(blPart.price)}</div>
                                        <div className={styles.property}><span>Remarks:</span> {blPart.remarks}</div>
                                    </div>
                                    <div className={styles.buttons}>
                                        {/* <FormControlLabel
                                            disabled={!this.props.isAdmin}
                                            checked={need.checked}
                                            style={{ paddingRight: "8%" }}
                                            labelPlacement="start"
                                            control={<Checkbox icon={<ShoppingCartOutlined />} checkedIcon={<ShoppingCart />} />}
                                            label={<span>{need.amount}</span>}
                                            onClick={async (event: any) => {
                                                // this.context.setShowLoadingBackground(true);
                                                // const updatedNeed = await this.context.needService.updateChecked(need.id, event.target.checked);
                                                // const index = this.state.needs.findIndex((need: Need) => need.id === updatedNeed.id);
                                                // const updatedNeeds: Need[] = this.state.needs;
                                                // updatedNeeds[index] = updatedNeed;
                                                // this.setState({ needs: updatedNeeds, filteredNeeds: updatedNeeds });
                                                // this.context.setShowLoadingBackground(false);
                                            }}
                                        /> */}



                                        {/* {this.props.isAdmin && <>
                                            <IconButton onClick={() => {
                                                this.setState({ need, openNeedDialog: true, dialogType: DialogType.Edit, creating: false });
                                            }}>
                                                <Edit />
                                            </IconButton>
                                            <IconButton onClick={() => {
                                                this.setState({ need, openConfirm2: true });
                                            }}>
                                                <Delete />
                                            </IconButton></>} */}

                                        {/* <IconButton onClick={() => {
                                            this.props.setNotification('Pdf generation has started', NotificationStyle.Info);
                                            this.context.pdfService.setsContainsPart(need);
                                        }}>
                                            <FontAwesomeIcon icon={faBuilding} />
                                        </IconButton> */}
                                    </div>
                                </div>
                            </ListItem>)
                        }
                    </List>

                {/* <PartDialog
                    isNeed={true}
                    message=""
                    show={this.state.openNeedDialog}
                    part={this.state.need}
                    handleShow={(openNeedDialog) => this.setState({ openNeedDialog })}
                    handleSubmit={async (amount: number, have: Have) => {
                        const need = this.state.need;
                        need.amount = amount;
                        need.have = have;
                        if (this.state.creating) {
                            const savedNeed = await this.context.needService.save(need);
                            const needs = [
                                savedNeed,
                                ...this.state.needs
                            ]
                            this.setState({
                                needs,
                                openNeedDialog: false,
                                filteredNeeds: needs
                            });
                        } else {
                            const updatedNeed = await this.context.needService.updateAmount(need.id, amount);
                            const index = this.state.needs.findIndex((need: Need) => need.id === updatedNeed.id);
                            const updatedNeeds: Need[] = this.state.needs;
                            updatedNeeds[index] = updatedNeed;
                            this.setState({ needs: updatedNeeds, filteredNeeds: updatedNeeds, openNeedDialog: false });
                        }
                    }}
                    submitText={this.state.dialogType}
                    creating={this.state.creating}
                    haves={this.state.haves} /> */}

                {/* <ConfirmationDialog
                    show={this.state.openConfirm1}
                    title="Not Found!"
                    handleConfirmation={() => this.setState({ openConfirm1: false })} >
                    <div>Part with element ID {this.state.searchString} not found!</div>
                </ConfirmationDialog> */}

                {/* <ConfirmationDialog
                    show={this.state.openConfirm2}
                    title={this.state.need.name}
                    handleConfirmation={async (confirm: boolean) => {
                        this.setState({ openConfirm2: false });
                        if (confirm) {
                            this.context.setShowLoadingBackground(true);
                            const deletedNeed = await this.context.needService.delete(this.state.need.id);
                            const filteredNeeds = this.state.needs.filter((need: Need) => need.id !== deletedNeed.id);
                            this.setState({ needs: filteredNeeds, filteredNeeds });
                            this.context.setShowLoadingBackground(false);
                        }
                    }} >
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <CardMedia
                            style={{ objectFit: "contain" }}
                            component="img"
                            alt={this.state.need.name}
                            height="100"
                            image={this.state.need.imageUrl}
                        />
                        <p>Are you sure you want to delete part with element ID {this.state.need.elementId}?</p>
                    </div>
                </ConfirmationDialog> */}

            </div>)
    }
}

export default connect(mapStateToProps,)(BLPartList);