import { Dispatch } from "react"
import { IRootState } from ".";
import { SetAdmin, SetAuthenticated, SetUnAuthenticated, UnsetAdmin } from "./actions";
import { Actions } from "./types";

export const mapStateToProps = ({ auth, admin }: IRootState) => {
  const { isAuthenticated } = auth;
  const { isAdmin } = admin;
  return { isAuthenticated, isAdmin };
}

export const mapDispatcherToProps = (dispatch: Dispatch<Actions>) => {
  return {
      SetAuthenticated: () => dispatch(SetAuthenticated()),
      SetUnAuthenticated: () => dispatch(SetUnAuthenticated()),
      SetAdmin: () => dispatch(SetAdmin()),
      UnsetAdmin: () => dispatch(UnsetAdmin())
  }
}
