import { FtpLocation } from "../models/ftpLocation.model";
import {
  axiosLegoBricks,
  axiosLegoDriveBlob,
  axiosLegoDriveForm,
  axiosLegoDriveJson,
} from "./axios.service";
import { saveAs } from "file-saver";
import { FtpItemInfo } from "../models/ftpItemInfo.model";
import { Instruction } from "../models/instruction.model";

export class InstructionService {
  
  public getLibraryRootInfo = async (
    ftpLocation: FtpLocation,
    setAlert: (alertMessage: string) => void
  ): Promise<FtpItemInfo[]> => {
    try {
      const response = await axiosLegoDriveJson().post(
        "/LibraryRootInfo",
        ftpLocation
      );
      return response.data;
    } catch (error: any) {
      setAlert(error.response.data);
      return [];
    }
  };

  public downLoadFile = async (
    ftpLocation: FtpLocation,
    setAlert: (alertMessage: string) => void
  ) => {
    try {
      const response = await axiosLegoDriveBlob().post("/GetFile", ftpLocation);

      const fileName = ftpLocation.path.split("/").pop();

      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      });

      saveAs(blob, fileName);
    } catch (error: any) {
      setAlert(error.response.data);
    }
  };

  public deleteFile = async (
    ftpLocation: FtpLocation,
    setAlert: (alertMessage: string) => void
  ): Promise<FtpItemInfo[]> => {
    try {
      const response = await axiosLegoDriveJson().post(
        "/DeleteFile",
        ftpLocation
      );
      return response.data;
    } catch (error: any) {
      setAlert(error.response.data);
      return [];
    }
  };

  public createDirectory = async (
    ftpLocation: FtpLocation,
    setAlert: (alertMessage: string) => void
  ): Promise<FtpItemInfo[]> => {
    try {
      const response = await axiosLegoDriveJson().post(
        "/MakeDirectory",
        ftpLocation
      );
      return response.data;
    } catch (error: any) {
      setAlert(error.response.data);
      return [];
    }
  };

  public removeDirectory = async (
    ftpLocation: FtpLocation,
    setAlert: (alertMessage: string) => void
  ) => {
    try {
      const response = await axiosLegoDriveJson().post(
        "/RemoveDirectory",
        ftpLocation
      );
      return response.data;
    } catch (error: any) {
      setAlert(error.response.data);
      return null;
    }
  };

  public uploadFile = async (
    file: File,
    credentials: number,
    path: string,
    setAlert: (alertMessage: string) => void
  ): Promise<FtpItemInfo[]> => {
    try {
      const formData = new FormData();
      formData.append("credentials", credentials.toString());
      formData.append("path", path);
      formData.append("file", new Blob([file]));
      // files.forEach((file: File) => {

      // });
      const response = await axiosLegoDriveForm().post("/UploadFile", formData);
      return response.data;
    } catch (error: any) {
      setAlert(error.response.data);
      return [];
    }
  };

  public rename = async (ftpLocation: FtpLocation, setAlert: (alertMessage: string) => void) => {
    try {
      const response = await axiosLegoDriveJson().post("/Rename", ftpLocation);
      return response.data;
    } catch (error: any) {
      setAlert(error.response.data);
      return null;
    }
  };

  public getInstructions = async (no: string): Promise<Instruction[]> => {
    try {
      const response = await axiosLegoBricks().get(`/Instructions/${no}`);
      return response.data;
    } catch (error: any) {
      return [];
    }
  };
}
