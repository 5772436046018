
import React, { FunctionComponent } from "react";
import styles from './SoldList.module.scss';
import { Sale } from "../../../models/sale.model";
import SoldItem from "./soldItem/SoldItem";

interface ISoldListProps {
    solds: Sale[];
}

export const SoldList: FunctionComponent<ISoldListProps> = (props: ISoldListProps): React.ReactElement => {
    return (
        <div className={styles.soldList}>
            <div className={styles.setContainer}>
                {props.solds.map((sold: Sale) =>
                    <SoldItem
                        key={sold.id}
                        sold={sold}
                    />)}
            </div>
        </div>
    );
}