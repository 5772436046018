import { action } from 'typesafe-actions';
import { SET_ADMIN, UNSET_ADMIN } from './types';
import { SET_AUTHENTICATED, SET_UNAUTHENTICATED } from './types';


export const SetAdmin = () => {
    return action(SET_ADMIN);
}

export const UnsetAdmin = () => {
    return action(UNSET_ADMIN);
}


export const SetAuthenticated = () => {
    return action(SET_AUTHENTICATED);
}

export const SetUnAuthenticated = () => {
    return action(SET_UNAUTHENTICATED);
}