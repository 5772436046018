import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { mapStateToProps } from "./store/propMapping";

interface IPublicRouteProps {
    component: React.ReactElement;
    path: string;
    exact: boolean;
    redirectPath: string;
}

type ReduxType = ReturnType<typeof mapStateToProps>;

const PublicRoute: FunctionComponent<IPublicRouteProps & ReduxType> = (props: IPublicRouteProps & ReduxType): React.ReactElement => {
    return (
        <Route path={props.path} exact={props.exact}>
            {props.isAuthenticated ? <Redirect from={props.path} to={props.redirectPath} /> : props.component}
        </Route>
    );
};

export default connect(mapStateToProps,)(PublicRoute);


