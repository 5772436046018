import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardMedia, Checkbox, FormControlLabel, IconButton, List, ListItem, Typography } from "@material-ui/core";
import { Delete, Edit, ShoppingCart, ShoppingCartOutlined } from '@material-ui/icons';
import React from "react";
import { connect } from "react-redux";
import AppContext, { AppContextProps } from "../../data/context";
import { Have } from "../../models/have.model";
import { Need } from "../../models/need.model";
import { mapStateToProps } from "../../store/propMapping";
import { ConfirmationDialog } from "../dialogs/ConfirmationDialog";
import { DialogType, PartDialog } from "../dialogs/partDialog/PartDialog";
import { NotificationStyle } from "../notification/Notification";
import SearchBar from "../searchBar/SearchBar";
import { INeedListProps, INeedListState } from "./INeedList";
import styles from './NeedList.module.scss';

type ReduxType = ReturnType<typeof mapStateToProps>;

class NeedList extends React.Component<INeedListProps & ReduxType, INeedListState> {

    public context!: AppContextProps;
    public static contextType: React.Context<AppContextProps> = AppContext;

    public state: INeedListState = {
        needs: [],
        filteredNeeds: [],
        searchString: '',
        openNeedDialog: false,
        openConfirm1: false,
        openConfirm2: false,
        need: new Need(),
        dialogType: DialogType.Create,
        haves: [],
        creating: true
    };

    public async componentDidMount() {
        this.context.setNavTitle('Missing parts');
        this.context.setShowLoadingBackground(true);
        const haves = await this.context.haveService.get();
        const needs = await this.context.needService.get();
        this.setState({ needs, filteredNeeds: needs, haves });
        this.context.setShowLoadingBackground(false);
    }

    private searchPart = async () => {
        this.context.setShowLoadingBackground(true);
        const need = await this.context.needService.byElementId(this.state.searchString);
        this.context.setShowLoadingBackground(false);
        need ? this.setState({ need, openNeedDialog: true, dialogType: DialogType.Create, creating: true }) : this.setState({ openConfirm1: true });
    }

    public render(): React.ReactElement<INeedListProps> {

        return (
            <div className={styles.needList}>
                <SearchBar
                    objects={this.state.needs}
                    filteredObjects={(filteredNeeds: Need[]) => this.setState({ filteredNeeds })}
                    filterAttr="elementId"
                    searchString={(inputText: string) => { this.setState({ searchString: inputText }, () => this.searchPart()) }} />
                {this.state.filteredNeeds.length === 0 && this.state.needs.length > 0 && <div style={{ textAlign: 'center' }}><h4>Not present in your part's need list!</h4></div>}
                <List style={{ overflow: 'auto', marginBottom: '56px' }} dense={false}>
                    {
                        this.state.filteredNeeds.map((need: Need, index: number) => <ListItem key={index}>
                            <div className={styles.listItem}>
                                <CardMedia
                                    style={{ objectFit: "contain", width: '20%' }}
                                    component="img"
                                    alt={need.name}
                                    height="70"
                                    image={need.imageUrl}
                                />
                                <div className={styles.information}>
                                    <Typography variant="h5" gutterBottom style={{ margin: 0 }}>{need.name}</Typography>
                                    <Typography variant="subtitle2" gutterBottom>{need.categoryName}</Typography>
                                    <div><span>Element ID:</span> {need.elementId}</div>
                                    <div><span>Part ID:</span> {need.no}</div>
                                    <div><span>Color:</span> {need.colorName}</div>
                                    {need.have ? <div><span>Missing in:</span> {need.have.no} {need.have.name}</div> : <></>}
                                    <br />
                                    {need.alternates ? <div>Alternates: {need.alternates.split(';').join(', ').slice(0, -1)}</div> : <></>}

                                </div>
                                <div className={styles.buttons}>
                                    <FormControlLabel
                                        disabled={!this.props.isAdmin}
                                        checked={need.checked}
                                        style={{ paddingRight: "8%" }}
                                        labelPlacement="start"
                                        control={<Checkbox icon={<ShoppingCartOutlined />} checkedIcon={<ShoppingCart />} />}
                                        label={<span>{need.amount}</span>}
                                        onClick={async (event: any) => {
                                            this.context.setShowLoadingBackground(true);
                                            const updatedNeed = await this.context.needService.updateChecked(need.id, event.target.checked);
                                            const index = this.state.needs.findIndex((need: Need) => need.id === updatedNeed.id);
                                            const updatedNeeds: Need[] = this.state.needs;
                                            updatedNeeds[index] = updatedNeed;
                                            this.setState({ needs: updatedNeeds, filteredNeeds: updatedNeeds });
                                            this.context.setShowLoadingBackground(false);
                                        }}
                                    />



                                    {this.props.isAdmin && <>
                                        <IconButton onClick={() => {
                                            this.setState({ need, openNeedDialog: true, dialogType: DialogType.Edit, creating: false });
                                        }}>
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => {
                                            this.setState({ need, openConfirm2: true });
                                        }}>
                                            <Delete />
                                        </IconButton></>}

                                    <IconButton onClick={() => {
                                        this.props.setNotification('Pdf generation has started', NotificationStyle.Info);
                                        this.context.pdfService.setsContainsPart(need);
                                    }}>
                                        <FontAwesomeIcon icon={faBuilding} />
                                    </IconButton>
                                </div>
                            </div>
                        </ListItem>)
                    }
                </List>
                <PartDialog
                    isNeed={true}
                    message=""
                    show={this.state.openNeedDialog}
                    part={this.state.need}
                    handleShow={(openNeedDialog) => this.setState({ openNeedDialog })}
                    handleSubmit={async (amount: number, have: Have) => {
                        const need = this.state.need;
                        need.amount = amount;
                        need.have = have;
                        if (this.state.creating) {
                            const savedNeed = await this.context.needService.save(need);
                            const needs = [
                                savedNeed,
                                ...this.state.needs
                            ]
                            this.setState({
                                needs,
                                openNeedDialog: false,
                                filteredNeeds: needs
                            });
                        } else {
                            const updatedNeed = await this.context.needService.updateAmount(need.id, amount);
                            const index = this.state.needs.findIndex((need: Need) => need.id === updatedNeed.id);
                            const updatedNeeds: Need[] = this.state.needs;
                            updatedNeeds[index] = updatedNeed;
                            this.setState({ needs: updatedNeeds, filteredNeeds: updatedNeeds, openNeedDialog: false });
                        }
                    }}
                    submitText={this.state.dialogType}
                    creating={this.state.creating}
                    haves={this.state.haves} />

                <ConfirmationDialog
                    show={this.state.openConfirm1}
                    title="Not Found!"
                    handleConfirmation={() => this.setState({ openConfirm1: false })} >
                    <div>Part with element ID {this.state.searchString} not found!</div>
                </ConfirmationDialog>

                <ConfirmationDialog
                    show={this.state.openConfirm2}
                    title={this.state.need.name}
                    handleConfirmation={async (confirm: boolean) => {
                        this.setState({ openConfirm2: false });
                        if (confirm) {
                            this.context.setShowLoadingBackground(true);
                            const deletedNeed = await this.context.needService.delete(this.state.need.id);
                            const filteredNeeds = this.state.needs.filter((need: Need) => need.id !== deletedNeed.id);
                            this.setState({ needs: filteredNeeds, filteredNeeds });
                            this.context.setShowLoadingBackground(false);
                        }
                    }} >
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <CardMedia
                            style={{ objectFit: "contain" }}
                            component="img"
                            alt={this.state.need.name}
                            height="100"
                            image={this.state.need.imageUrl}
                        />
                        <p>Are you sure you want to delete part with element ID {this.state.need.elementId}?</p>
                    </div>
                </ConfirmationDialog>

            </div>)
    }
}

export default connect(mapStateToProps,)(NeedList);