
import React, { useState } from 'react';
import { AlternateSetService } from '../services/alternateSet.service';
import { BrickLinkService } from '../services/bricklink.service';
import { HaveService } from '../services/have.service';
import { InstructionService } from '../services/instruction.service';
import { LocalStorageService } from '../services/localstorage.service';
import { NeedService } from '../services/need.service';
import { NewsService } from '../services/news.service';
import { PdfService } from '../services/pdf.service';
import { RewardService } from '../services/reward.service';
import { SaleService } from '../services/sale.service';
import { ThemeService } from '../services/theme.service';
import { UserService } from '../services/user.service';
import { WantService } from '../services/want.service';
import { DataService } from './DataService';
import { AppView } from './Enums';

export interface AppContextProps {
    dataService: DataService;
    haveService: HaveService;
    wantService: WantService;
    needService: NeedService;
    saleService: SaleService;
    userService: UserService;
    localStorageService: LocalStorageService;
    pdfService: PdfService;
    alternateSetService: AlternateSetService;
    instructionService: InstructionService;
    newsService: NewsService;
    rewardService: RewardService;
    brickLinkService: BrickLinkService;
    rewardPoints: number;
    setRewardPoints: (value: number) => void;
    view: AppView;
    setView: (view: AppView) => void;
    navTitle: string;
    setNavTitle: (navTitle: string) => void;
    showLoadingBackground: boolean;
    setShowLoadingBackground: (showLoadingBackground: boolean) => void;
    totalSpent: number;
    getTotalSpent: () => void;
    saleBalance: number;
    getSaleBalance: () => void;

}

const dataService = new DataService(new ThemeService());

const appProviderValue: AppContextProps = {
    dataService,
    haveService: new HaveService(dataService),
    wantService: new WantService(dataService),
    needService: new NeedService(dataService),
    saleService: new SaleService(),
    userService: new UserService(),
    localStorageService: new LocalStorageService(),
    pdfService: new PdfService(dataService),
    alternateSetService: new AlternateSetService(),
    instructionService: new InstructionService(),
    newsService: new NewsService(),
    rewardService: new RewardService(),
    brickLinkService: new BrickLinkService(),
    rewardPoints: 0,
    setRewardPoints: () => { },
    view: AppView.LegoBricks,
    setView: () => { },
    navTitle: '',
    setNavTitle: () => { },
    showLoadingBackground: false,
    setShowLoadingBackground: () => { },
    totalSpent: 0,
    getTotalSpent: () => { },
    saleBalance: 0,
    getSaleBalance: () => { }
}

const AppContext = React.createContext<AppContextProps>(appProviderValue);

export const AppContextProvider = (props: any) => {
    const [rewardPoints, setRewardPoints] = useState(0);
    const [view, setView] = useState(AppView.LegoBricks);
    const [navTitle, setNavTitle] = useState('');
    const [showLoadingBackground, setShowLoadingBackground] = useState(false);
    const [totalSpent, setTotalSpent] = useState(0);
    const [saleBalance, setSaleBalance] = useState(0);

    const getTotalSpent = async () => {
        const totalSpent = await context.haveService.totalSpent();
        setTotalSpent(totalSpent);
    }

    const getSaleBalance = async () => {
        const saleBalance = await context.saleService.getBalance();
        setSaleBalance(saleBalance);
    }

    const context: AppContextProps = {
        ...appProviderValue,
        rewardPoints,
        setRewardPoints,
        view,
        setView,
        navTitle,
        setNavTitle,
        showLoadingBackground,
        setShowLoadingBackground,
        totalSpent,
        getTotalSpent,
        saleBalance,
        getSaleBalance
    }

    return (<AppContext.Provider value={context}>{props.children}</AppContext.Provider>)
}

export default AppContext;