import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { FunctionComponent } from "react";

interface IInfoDialogProps {
    show: boolean;
    title: string;
}

const InfoDialog: FunctionComponent<IInfoDialogProps> = (props: React.PropsWithChildren<IInfoDialogProps>): React.ReactElement => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.show);
    }, [props.show])

    return (
        <Dialog
            open={show}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShow(false)} color="primary" autoFocus>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default InfoDialog;
