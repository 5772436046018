import { Card, CardActionArea, CardContent, CardMedia } from "@material-ui/core";
import React from "react";
import AppContext, { AppContextProps } from "../../data/context";
import { NewsItem } from "../../models/news.model";
import { INewsOverviewProps, INewsOverviewState } from "./INewsOverview";
import styles from './NewsOverview.module.scss';


class NewsOverview extends React.Component<INewsOverviewProps, INewsOverviewState> {

    public context!: AppContextProps;
    public static contextType: React.Context<AppContextProps> = AppContext;

    public state: INewsOverviewState = {
        newsArticles: new Array<NewsItem>()
    };

    public async componentDidMount() {
        const newsArticles = await this.context.newsService.get();
        this.setState({ newsArticles });
    }

    public render(): React.ReactElement<INewsOverviewProps> {
        return (
            <div className={styles.newsOverview}>
                {this.state.newsArticles.map((newsArticle: NewsItem, index: number) => <Card key={index} style={{ marginBottom: 30 }}>
                    <CardActionArea onClick={() => window.open(newsArticle.url, '_blank')}>
                        <h2>{newsArticle.title}</h2>
                        <CardMedia
                            component="img"
                            height="140"
                            image={newsArticle.imageUrl}
                            alt={newsArticle.title}
                            style={{ objectFit: "contain" }}
                        />
                        <CardContent>
                            <p>{newsArticle.title}</p>

                        </CardContent>
                        {/* <CardActionArea>
                                <GenericButton type={ButtonType.Round} onClick={() => window.open(newsArticle.url, '_blank')}><FontAwesomeIcon icon={faExternalLink} /></GenericButton>
                            </CardActionArea> */}
                    </CardActionArea>
                </Card>)}
            </div>);
    }
}

export default NewsOverview;