import React, { ReactNode } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { FunctionComponent } from "react";

interface IConfirmationDialogProps {
    children: ReactNode;
    show: boolean;
    title: string;
    // content: React.ReactElement;
    handleConfirmation: (confirm: boolean) => void;
}

export const ConfirmationDialog: FunctionComponent<IConfirmationDialogProps> = (props: IConfirmationDialogProps): React.ReactElement => {

    return (
        <Dialog
            open={props.show}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleConfirmation(false)} color="primary">Cancel</Button>
                <Button onClick={() => props.handleConfirmation(true)} color="primary" autoFocus>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}
