import React, { useContext } from 'react';
import { Button, Card, CardContent, CardHeader, CardMedia, FormControlLabel, IconButton, List, ListItem, ListItemIcon, ListItemText, Switch } from "@material-ui/core";
import { FunctionComponent } from "react";
import { Delete } from '@material-ui/icons';
import { mapStateToProps } from '../../../../store/propMapping';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faHandHoldingUsd, faHashtag, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './SaleItem.module.scss';
import { Sale } from '../../../../models/sale.model';
import AppContext from '../../../../data/context';

interface ISaleItemProps {
    sale: Sale;
    deleteSale: (sale: Sale) => void;
    sell: (sale: Sale) => void;
}

type ReduxType = ReturnType<typeof mapStateToProps>;

const SaleItem: FunctionComponent<ISaleItemProps & ReduxType> = (props: ISaleItemProps & ReduxType): React.ReactElement => {
    const context = useContext(AppContext);

    return (
        <Card key={props.sale.no} className={styles.saleItem}>
            <CardHeader
                titleTypographyProps={{ variant: 'h6' }}
                title={props.sale.name}
                subheader={`${props.sale.themeName}, ${props.sale.release}`}
                action={
                    props.isAdmin && <IconButton onClick={() => { props.deleteSale(props.sale) }}>
                        <Delete />
                    </IconButton>
                }
            />
            <CardMedia
                style={{ objectFit: "contain" }}
                component="img"
                alt={props.sale.name}
                height="140"
                image={props.sale.imageUrl}
            />
            <CardContent>
                <List dense={true}>
                    <ListItem>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={true}
                                    checked={props.sale.closed}
                                    color="primary"
                                />
                            }
                            label={props.sale.closed ? "Closed" : "Opened"}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faBuilding} />
                        </ListItemIcon>
                        <ListItemText primary={props.sale.no} />
                    </ListItem>
                    {props.isAdmin && <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faMoneyBillAlt} />
                        </ListItemIcon>
                        <ListItemText primary={context.dataService.moneyFormatter(props.sale.purchasePrice)} />
                    </ListItem>}
                    <ListItem>
                        <ListItemIcon>
                            <FontAwesomeIcon size="lg" icon={faHashtag} />
                        </ListItemIcon>
                        <ListItemText primary={`${props.sale.partsQuantity} piece(s)`} />
                    </ListItem>
                    {<div style={{ textAlign: "center" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.sell(props.sale)}
                        startIcon={<FontAwesomeIcon size="lg" icon={faHandHoldingUsd} />}>Sell</Button>
                </div>}
                </List>
            </CardContent>
        </Card>
    )
}

export default connect(mapStateToProps)(SaleItem);