import { Button, Input, InputAdornment, InputLabel } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { AccountCircle, Lock } from "@material-ui/icons";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Role } from "../../data/Constants";
import AppContext, { AppContextProps } from "../../data/context";
import { UserAuth } from "../../models/userAuth.model";
import { mapDispatcherToProps, mapStateToProps } from "../../store/propMapping";
import { NotificationStyle } from "../notification/Notification";
import { ILoginProps, ILoginState } from "./ILogin";
import styles from './Login.module.scss';

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

class Login extends React.Component<ILoginProps & ReduxType & RouteComponentProps, ILoginState> {

    public context!: AppContextProps;
    public static contextType: React.Context<AppContextProps> = AppContext;

    public state: ILoginState = {
        username: '',
        password: ''
    };

    public componentDidMount() {
        this.context.setNavTitle('Login');
    }

    private login = () => {
        this.context.setShowLoadingBackground(true);
        const { username, password } = this.state;
        this.context.userService.authenticate(new UserAuth(username, password))
            .then((response: any) => {
                this.context.localStorageService.setToken(response.data.token);
                const tokenObject = this.context.localStorageService.getTokenObject();
                this.props.setUsername(tokenObject.Username);
                this.props.showNotification(`Welcome ${response.data.username}`, NotificationStyle.Info);
                this.props.SetAuthenticated();
                response.data.role.name === Role.Admin ? this.props.SetAdmin() : this.props.UnsetAdmin();
                this.context.getTotalSpent();
                this.context.getSaleBalance();
                this.props.history.push('/HaveList');
                this.context.setShowLoadingBackground(false);

            })
            .catch((error: any) => {
                this.props.showNotification(error.response.data, NotificationStyle.Error);
                this.props.SetUnAuthenticated();
                this.props.UnsetAdmin();
                this.context.setShowLoadingBackground(false);
            });
    }

    public render(): React.ReactElement<ILoginProps> {
        return (
            <div className={styles.login}>

                <FormControl className={styles.input}>
                    <InputLabel htmlFor="username">Username</InputLabel>
                    <Input
                        id="username"
                        value={this.state.username}
                        startAdornment={
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        }
                        onChange={(event) => this.setState({ username: event.target.value })}
                        onKeyUp={(event) => {
                            if (event.key === 'Enter') {
                                this.login();
                            }
                        }}
                    />
                </FormControl>
                <FormControl className={styles.input}>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <Input
                        type="password"
                        id="password"
                        value={this.state.password}
                        startAdornment={
                            <InputAdornment position="start">
                                <Lock />
                            </InputAdornment>
                        }
                        onChange={(event) => this.setState({ password: event.target.value })}
                        onKeyUp={(event) => {
                            if (event.key === 'Enter') {
                                this.login();
                            }
                        }}
                    />
                </FormControl>
                <div className={styles.loginButton}>
                    <Button variant="contained" color="primary"
                        disabled={this.state.username === '' || this.state.password === ''}
                        onClick={this.login}>Login</Button>
                </div>
            </div>
        );

    }
}

export default connect(mapStateToProps, mapDispatcherToProps)(withRouter(Login));
