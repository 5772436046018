import React, { ReactElement, useState } from 'react';
import { Button, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemIcon, ListItemText, TextField } from "@material-ui/core";
import { FunctionComponent } from "react";
import { Flag, Money, Palette, Save } from '@material-ui/icons';
import styles from './SetDialog.module.scss';
import { Set } from '../../../models/set.model';

export const DialogType = {
  Register: 'Register',
  Edit: 'Edit'
}

interface ISetDialogProps {
  show: boolean;
  submitText: string;
  set: Set;
  handleShow: (showSetDialog: boolean) => void;
  handleSubmit: (price?: number) => void;
  registerPrice: boolean;
}

export const SetDialog: FunctionComponent<ISetDialogProps> = (props: ISetDialogProps): ReactElement => {
  const [price, setPrice] = useState(0);

  return (
    <Dialog open={props.show} className={styles.partDialog}>
      <DialogTitle>{`${props.set.no} ${props.set.name}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <CardMedia
            style={{ objectFit: "contain" }}
            component="img"
            alt={props.set.name}
            height="150"
            image={props.set.imageUrl}
          />

        </DialogContentText>

        <List dense={true}>
          <ListItem>
            <ListItemIcon>
              <Palette />
            </ListItemIcon>
            <ListItemText primary={props.set.themeName} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Money />
            </ListItemIcon>
            <ListItemText primary={`${props.set.partsQuantity} piece(s)`} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Flag />
            </ListItemIcon>
            <ListItemText primary={props.set.release} />
          </ListItem>
        </List>

        {props.registerPrice && <TextField label="Price" type="number" value={price} inputProps={{ step: 0.01 }} onChange={(event) => setPrice(+event.target.value)} />}

      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setPrice(0);
          props.handleSubmit(props.registerPrice ? price : undefined);
          props.handleShow(false);
        }}
          disabled={price < 0}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Save />}>
          {props.submitText}
        </Button>
        <Button onClick={() => {
          setPrice(0);
          props.handleShow(false);
        }} color="primary">Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
