import { Sale } from "../models/sale.model";
import { axiosLegoBricks } from "./axios.service";


export class SaleService {

    public getForSales = async (): Promise<Sale[]> => {
        const response = await axiosLegoBricks().get('/Sale/forSale');
        return response.data;
    }

    public getSolds = async (): Promise<Sale[]> => {
        const response = await axiosLegoBricks().get('/Sale/sold');
        return response.data;
    }

    public getBalance = async (): Promise<number> =>  (await axiosLegoBricks().get('/Sale/balance')).data;

    public save = async (sale: Sale): Promise<Sale> => {
        const response = await axiosLegoBricks().post('/Sale', sale);
        return response.data;
    }

    public sell = async (id: number, price: number) => {
        const response = await axiosLegoBricks().patch(`/Sale/sell/${id}`, [
            {
                value: price,
                path: "/sellingPrice",
                op: "replace"
            },
            {
                value: true,
                path: "/sold",
                op: "replace"
            }
        ]);
        return response.data;
    }

    public delete = async (id: number) => {
		const response = await axiosLegoBricks().delete(`/Sale/${id}`);
		return response.data;
	}
}