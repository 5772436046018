import { Reward } from "../models/reward.model";
import { axiosLegoBricks } from "./axios.service";

export class RewardService {
  public get = async (): Promise<Reward[]> => {
    const response = await axiosLegoBricks().get("/Reward");
    return response.data;
  };

  public save = async (reward: Reward): Promise<Reward> => {
    const response = await axiosLegoBricks().post("/Reward", reward);
    return response.data;
  };

  public points = async (): Promise<number> => {
    try {
      const response = await axiosLegoBricks().get("Reward/points");
      return response.data;
    } catch {
      return 0;
    }
  };
}
